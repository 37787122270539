/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');
//require('../css/app.less');
 
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

//var greet = require('./greet');


$(document).ready(function() {
	
	var newSortCart;
	var lang = $('body').data('lang');  
	
	$('.loading').hide();
		
	$(window).bind("pageshow", function(event) {
		$(".loading").hide();
	});
	
	pushFooter();
	
	 $('.slider-for').slick({
		   slidesToShow: 1,
		   slidesToScroll: 1,
		   arrows: true,
		   fade: true,
		   dots: false,
		   autoplay: true,
		   autoplaySpeed: 10000,
		   //asNavFor: '.slider-nav'
		 });
//		 $('.slider-nav').slick({
//		   slidesToShow: 3,
//		   slidesToScroll: 1,
//		   asNavFor: '.slider-for',
//		   dots: false,
//		   focusOnSelect: true
//		 });

		 $('a[data-slide]').click(function(e) {
		   e.preventDefault();
		   var slideno = $(this).data('slide');
		   $('.slider-for').slick('slickGoTo', slideno - 1);
		   //console.log(222);
		 });
		 
		 $('.slider-for').on('beforeChange', function() {
			    var dataId = $('.slick-current').attr("data-slick-index"); 
			    //console.log(parseInt(dataId)+1);
			    //dataId = parseInt(dataId)+1;
			    $('.sliderNav').removeClass('activeSlide');
			    $('.slide' + dataId).addClass('activeSlide');			    
			});
		 
		 $('.slider-for').on('afterChange', function() {
			    var dataId = $('.slick-current').attr("data-slick-index"); 
			    //console.log(parseInt(dataId)+1);
			    //dataId = parseInt(dataId)+1;
			    $('.sliderNav').removeClass('activeSlide');
			    $('.slide' + dataId).addClass('activeSlide');			    
			});
	
	//$('.alert').delay(5000).hide(500);
		 
	 $.each($('.addedItem'), function(i,e) {
//			$(e).addClass('addedItemDelay');
		setTimeout(function(){
			$(e).addClass('addedItemDelay');
		},1000);
	 });
 
	setTimeout(function(){
		 $('.item').removeClass('addedItem').removeClass('addedItemDelay');
	},6000);
	
	$("#chatMsgContainer").scrollTop($("#chatMsgContainer")[0].scrollHeight);
	
	//btnClicked
	$('body').on('click', '.buttonEff, .smallBtn', function(){
		
		if(!$(this).attr('type') == 'submit') {
		
			$('.buttonEff').removeClass('btnClicked');
			$(this).addClass('btnClicked');
			
		}
	});
	
	$('body').on('click', '#mainNav a, a', function(){
		if($(this).attr('href') != 'javascript:void(0);' && $(this).attr('href') != '' && $(this).attr('target') != 'blank' && $(this).attr('data-type') != 'ajax' && !$(this).hasClass('noLoad') && !$(this).hasClass('noCartBoxLink')){
			$('.loading').show();
			
			
		}
	})

	
	//openChat When home && logged in
	
//	if($('#home').length > 0){
//		alert("home");
//		$('#chatHeadlineLeft').click(function(){
//			
//		});
//		
//	}
	
	$('#mainNavBtnChat').click(function(e){
		e.preventDefault();
		
		//console.log($(document).width());
		
		if($(document).width() < 721) { 
			
			if($('#chatBoxRight').is(':visible')){
				
					$('#chatBoxRight').hide();
					$('#chatShowBtn').show();
				} else {
			
					$('#chatBoxRight').show();
					$('#chatShowBtn').hide();
					
					//$('#chatContentCol').toggle();
					//$('#chatHeadlineLeft').toggleClass('chatHeadlineLeftActive');
					$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
					
				}
			} else {
			
				$('#chatBoxRight').show();
				$('#chatShowBtn').hide();
				$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
			}
		
		//$('#chatHeadlineLeft').click();
	})
	
	var checkChat = function(){
		var timeInterval = 30000;
		if ($("#chatHeadlineLeft").length){
	
			setTimeout(
				function ()
			        {
					
					//check if last sender is HR
					//console.log("before chat U");
					if(!$('#chatMsgContainer .msgRow:last-child div .msgContainer').hasClass('bgHr')){
						//console.log("after chat U");
						
						var lastMsgId = $('#chatMsgContainer .msgRow:last-child').attr('data-msgid');
						var lang = $('body').attr('data-lang');
						//console.log(lastMsgId);
						
						var data = { lastMsgId : lastMsgId, lang : lang }
						
				        $.ajax({
				           url: '/getChatUpdate',
				           cache: false,
				           type: "POST",
				           data: data,
				           complete: function (response) {
					           	
				           //	console.log(response.responseText);
				        	   
				           		if (response.responseText != ""){
				           			//$('#chatHeadlineLeft').addClass('blink');
				           			$('#chatShowBtn').addClass('blink')
				           			$('#chatMsgContainer').append(response.responseText);
			           				$('#chatHeadlineText').hide();
			           				$('#chatNewMsgText').fadeIn(500);
			           				//$('.chatBoxImgContainer').hide();
			           				//$('#chatContentContainer').fadeIn(500)
			           				$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
						        	//   console.log(response.responseText);
				           		}
	//			           			$('#chatMsgContainer').append(response);
				           			
	//			           			$("#menuChatBtn").addClass("chatBtnNewMsg");
	//			           			$("#menuChatBtn").text("Chat - Neue Nachricht");
	//			           			// setInterval(function(){blink()}, 1000);
	//			           			//check if lightbox chat is visible and add new message
	//			           			if ($(".lightboxChatContainer").is(":visible")) {
	//			           				//get new messages
	//			           				$('.userChatContainer').empty();
	//				        			$('.userChatContainer').append(response.responseText);
	//				        			var d = $('#lightboxInner');
	//									d.scrollTop(d.prop("scrollHeight"));
					        		//}
	//			           			
	//			           		} else {
	//			           			$("#menuChatBtn").removeClass("chatBtnNewMsg");
	//			           			$("#menuChatBtn").text("Chat");
	//			           		}
				           	//alert(response);
				           	
				           		
				           	}
				        });
						
			        }
					checkChat();
			    },
			    timeInterval
			);
		};
	};
	
	checkChat();
	
	//hide alert
	$('body').on('click', '.alert-close', function(){
		$('.alert').slideToggle(300);
	});

	//lang select close google bar
	$('body').on('click', '.goog-te-banner-frame', function(){
		
		$('.goog-close-link').click();
	});
	
	//cookies
//	function checkCookieConsent(){
//		if($('#btnCookieConsentShow').length > 0){
//			$('#btnCookieConsentShow').click();
//		}
//	}
//	
//	checkCookieConsent();
//	
//	$('body').on('click', '.btnSetCookieConset', function(){
//		var data = { consent : '1' };
//		var url = "/setCookieConsent";
//		
//		$.ajax({
//	        type: 'POST',
//	        url: url,
//	        data: data,
//
//	        success: function () {
//	        	
//	        },
//	        error: function (data) {
//	            console.log('Error:', data);
//	        }
//	    });
//	});
	
	//animate chat on home
	/*if($('.chatHome').length>0){
		//$('.chatHome').addClass('animate');
		$(".chatHome").animate({ 
	        top: "-=500px",
	      }, 'slow' );
	}
	*/
	
	
	$('#mobileNav').click(function(){
		$menuContent = $('.menuContent');
		if ($menuContent.is(':visible')){
			$menuContent.hide();
		} else if ($menuContent.is(':hidden')){
			$menuContent.show();
		} 
	});
	
	//search Results
	$('#mainSearchInput').keyup(delay(function(e){
				
		var searchStringTerm = $('#mainSearchInput').val().replace(/\–/g, "-");	
		//console.log(searchStringTerm);
		var lang = $('body').attr('data-lang');
		//alert(lang);
		if (searchStringTerm != '') {			
			var data = { searchString: searchStringTerm, lang: lang };						
			var ajax = getDataSearch(data, lang);
		} else {
			$('#searchFullResults').empty();
			$('#smartSearchResultContainer').hide();
		}
		
		if(e.which == 13){
			$('.searchInputGo').click();
		}
		
		
	}, 300));
	
	function getDataSearch(data, lang) {
		//console.log("1234aaa");
		//alert(lang);
	   return $.ajax({
	    	url: "/" + lang + "/getSearchResults",
			type: "POST",
			data: data,
			success  : handleDataSearch 
	    })
	}
	
	function handleDataSearch(response){
		$('.searchLoadingIcon').hide();
		$('#searchResultContainer').empty();
		$('#searchResultContainer').append(response);
		if(!$('#searchResultContainer').is(':visible')){
			$('#searchResultContainer').slideToggle(200);
		}
	}
	
	//delay for keyup function
	function delay(callback, ms) {
	  var timer = 0;
	  return function() {
	    var context = this, args = arguments;
	    clearTimeout(timer);
	    timer = setTimeout(function () {
	      callback.apply(context, args);
	    }, ms || 0);
	  };
	}
	
	//end search results
	
	
	//hide home Content
	$('body').on('click', '.homeHide', function(){
		$('.homeSlideContainer').hide();
	});
	
	
	$('body').on('dblclick', '.adressItem', function(){
		$('.fancybox-close-small').click();
		//alert(222);
	});
	
	
	//cartDetail AdressList cartAdressList select adress
	$('body').on('click', '.adressItem', function(){
		
		$('.EditBtnNoAdressSelected').hide()
		$('.EditBtnMainAdressSelected').hide();
		
		var lang = $('body').attr('data-lang');
		
		var adressId = $(this).attr('data-adressid');
		var clickedRow = $(this).attr('data-row');
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var billingOrShipping = 'adress_id';
		var orgCountry = '';
		var selectedCountry = '';
		
		if ($('.fancyBoxCartAdressList').hasClass('billingAdress')){
			billingOrShipping = 'billing_adress_id';
		};
			
		$('.adressItem').removeClass('rowSelected');
		$('.adressItem_row' + clickedRow).addClass('rowSelected');
		
		if ($('.fancyBoxCartAdressList').hasClass('billingAdress')){
			cartAdressContainer = $('.cartDetailSelectedBillingAdress')
		} else {
			orgCountry = $('.cartDetailSelectedShippingAdress').attr('data-shippingcountry');
			selectedCountry = $('.land.rowSelected').attr('data-landkzl');
		};
		
		var adressId = $('.firma.rowSelected').attr('data-adressid');
		$('.adressId').attr('data-adressid', adressId);
		
		$('#newAdressCompanyName').val($('.firma.rowSelected').text()); 
		$('#newAdressStreet').val($('.strasse.rowSelected').text());
		$('#newAdressContactPerson').val($('.ansprechpartner.rowSelected').text());
		$('#newAdressPlz').val($('.plz.rowSelected').text());
		$('#newAdressCity').val($('.stadt.rowSelected').text());
		$('#newAdressPhone').val($('.telefon.rowSelected').text());
		$('#newAdressEmail').val($('.email.rowSelected').text());
		
		var landKzl = $('.land.rowSelected').attr('data-landkzl');
		$('#newAdressCountry').val(landKzl);
		
		//$('#newAdressCountry option[value = ' + landKzl + ']').attr('selected', 'selected');
		
		var data = { adressId: adressId, cartId: cartId, billingOrShipping : billingOrShipping, orgCountry : orgCountry, selectedCountry : selectedCountry, lang : lang };	
		
		var ajax = changeActiveCartAdressId(data , lang);

		//check if country changed => Warning + Versandarten zurücksetzen
		if (orgCountry != selectedCountry){
			$('.cartDetailSelectedShippingAdress').attr('data-shippingcountry', selectedCountry);
			$('.cartChangedShippingCountryWarning').show();
		}
		
		//alert(adressId);
	});
	
	function changeActiveCartAdressId(data, lang) {
		//console.log("1234aaa");
	   return $.ajax({
	    	url: "/" + lang + "/changeActiveCartAdressId",
			type: "POST",
			data: data,
			success  : handleDataCartReload
	    })
	}
	
	$('body').on('click', '.cartAdressListEditIcon', function(){
		//console.log(222);
		$('.fancyBoxCartAdressListBtnEditAdress').delay(600).click();
	});
	
	//cartAdressList create newAdress
	$('body').on('click', '.fancyBoxCartAdressListBtnNewAdress', function(){
		$('.EditBtnNoAdressSelected').hide()
		$('.EditBtnMainAdressSelected').hide();
		
		$('.newAdress').show();
		$('.fancyBoxCartAdressListBtnNewAdress').show();
		$('.editAdress').hide();	
		$('.adressId').attr('data-adressid', "");
		
		$('#AdressListContainerNewAdress').not(':visible').slideToggle();
		
		$('#newAdressCompanyName').val(""); 
		$('#newAdressStreet').val("");
		$('#newAdressContactPerson').val("");
		$('#newAdressPlz').val("");
		$('#newAdressCity').val("");
		$('#newAdressPhone').val("");
		$('#newAdressEmail').val("");
		
		var landKzl = 'DE';
		
		$('#newAdressCountry option[value = ' + landKzl + ']').attr('selected', 'selected');
		
		write2Log('click','Warenkorb - Button Adresse neu anlegen ')

	});
	
	//copy values of selected adress
	$('body').on('click', '.fancyBoxCartAdressListBtnCopySelectedAdresss', function(){

		$('#newAdressCompanyName').val($('.firma.rowSelected').text()); 
		$('#newAdressStreet').val($('.strasse.rowSelected').text());
		$('#newAdressContactPerson').val($('.ansprechpartner.rowSelected').text());
		$('#newAdressPlz').val($('.plz.rowSelected').text());
		$('#newAdressCity').val($('.stadt.rowSelected').text());
		$('#newAdressPhone').val($('.telefon.rowSelected').text());
		$('#newAdressEmail').val($('.email.rowSelected').text());
		
		var landKzl = $('.land.rowSelected').attr('data-landkzl');
		
		$('#newAdressCountry option[value = ' + landKzl + ']').attr('selected', 'selected');
		
		write2Log('click','Warenkorb - Button ausgewählte Adresse kopieren ')

	});
	

	//cartAdressList edit Adress
	$('body').on('click', '.fancyBoxCartAdressListBtnEditAdress', function(){
		
		write2Log('click','Warenkorb - Button Adresse editieren ')
		
		$('.EditBtnNoAdressSelected').hide()
		$('.EditBtnMainAdressSelected').hide();
		$('.fancyBoxCartAdressListBtnCopySelectedAdresss').hide();

		if ($('.adressItem').hasClass('rowSelected')){
			
			if ($('.firma.rowSelected').attr('data-adresstype') != 'H') {
			
				$('.newAdress').hide();
				$('.editAdress').show();
				
				var adressId = $('.firma.rowSelected').attr('data-adressid');
				$('.adressId').attr('data-adressid', adressId);
				
				$('#newAdressCompanyName').val($('.firma.rowSelected').text()); 
				$('#newAdressStreet').val($('.strasse.rowSelected').text());
				$('#newAdressContactPerson').val($('.ansprechpartner.rowSelected').text());
				$('#newAdressPlz').val($('.plz.rowSelected').text());
				$('#newAdressCity').val($('.stadt.rowSelected').text());
				$('#newAdressPhone').val($('.telefon.rowSelected').text());
				$('#newAdressEmail').val($('.email.rowSelected').text());
				
				var landKzl = $('.land.rowSelected').attr('data-landkzl');
				
				$('#newAdressCountry option[value = ' + landKzl + ']').attr('selected', 'selected');
										
				$('#AdressListContainerNewAdress').not(':visible').slideToggle();
			} else {
				$('.EditBtnMainAdressSelected').show();
				$('#AdressListContainerNewAdress').hide();
			}
		} else {
			$('.EditBtnNoAdressSelected').show();
			$('#AdressListContainerNewAdress').hide();
		}
	});
	
	//submit form
	$('body').on('click', '.fancyBoxCartAdressListBtnNewAdressSave, .fancyBoxCartAdressListBtnEditAdressSave', function(){
		
		write2Log('click','Warenkorb - Button Neue Adresse speichern ')
		
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var billingOrShipping = 'adress_id';
		
		if ($('.fancyBoxCartAdressList').hasClass('billingAdress')){
			billingOrShipping = 'billing_adress_id';
		};
		
		var firma = $('#newAdressCompanyName').val();
		var strasse = $('#newAdressStreet').val();
		var ansprechpartner = $('#newAdressContactPerson').val();
		var plz = $('#newAdressPlz').val();
		var stadt = $('#newAdressCity').val();
		var telefon = $('#newAdressPhone').val();
		var land = $('#newAdressCountry option:selected').val();
		var email = $('#newAdressEmail').val();
		
		
		var error = false;
		
		$('.formRequired').each(function(i, obj){
			$(this).removeClass('borderRed');
			if (!$(this).val()){
				$(this).next('.formRequiredError').show();
				$(this).addClass('borderRed');
				error = true;
			}
		});
		
		if(email != ''){
			var checkEmail = isEmail(email)
			
			//console.log(checkEmail);
		}
		
		if (error == false){
			//ajax go on
			
			adressId = "";
			
			if ($(this).hasClass('fancyBoxCartAdressListBtnEditAdressSave')) {
				var adressId = $('.adressId').attr('data-adressid');
			}
			
			if ($(this).hasClass('fancyBoxCartAdressListBtnEditAdressSave') && $('.firma.rowSelected').attr('data-adresstype') == 'H') {
				$('.EditBtnMainAdressSelected').show();
				$('#AdressListContainerNewAdress').hide();
			} else {
				var lang = $('body').attr('data-lang');
				
				var data = { adressId : adressId, firma : firma, strasse : strasse, ansprechpartner : ansprechpartner, plz : plz, stadt : stadt, telefon : telefon, email : email, land : land, cartId : cartId, billingOrShipping : billingOrShipping, lang : lang  };	
				
				var ajax = saveOrEditNewAdress(data);
			}
			

		}
		
	});
	
	$('body').on('click', '#cartToggleVisibilityGridTop', function(){
		$('.cartDetailGridTop').toggle();
		
		var isVisible = 1;
		if($('.cartDetailGridTop').is(':hidden')){
			isVisible = 0;
		}
		
		var data = { isVisible : isVisible };
		var url = "/setCartTopGridHidden";
		
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,

	        success: function () {
	        	
	        },
	        error: function (data) {
	            console.log('Error:', data);
	        }
	    });
		
	});
	
	$('body').on('click', '.fancyBoxCartAdressList .fancybox-close', function(e){

		if($('.fancyBoxCartAdressList').hasClass('billingAdress')){
			$('.cartDetailSelectedBillingAdress').addClass('changed');
		} else {
			$('.cartDetailSelectedShippingAdress').addClass('changed');			
		}
		
		$('.fancybox-close-small').click();
		
		setTimeout(function(){
			$('.cartDetailGridTop .colContent').removeClass('changed');
		},5000);
		
	});
	
	$('body').on('click', '.fancyBoxCartShipmentList .fancybox-close', function(e){
		$('.cartDetailSelectedShipment').addClass('changed');		
		
		$('.fancybox-close-small').click();
		
		setTimeout(function(){
			$('.cartDetailGridTop .colContent').removeClass('changed');
		},5000);
		
	});
	
	$('body').on('click', '.cartCommentBtnEditSave', function(){
		$('.cartDetailComment').addClass('changed');
		
		$('.fancybox-close-small').click();
		
		setTimeout(function(){
			$('.cartDetailGridTop .colContent').removeClass('changed');
		},5000);
	});

	$('body').on('click', '#fancyBoxCartDeliveryNote .fancybox-close', function(){
		$('.cartDetailSelectedDeliveryNote').addClass('changed');
		
		$('.fancybox-close-small').click();
		
		setTimeout(function(){
			$('.cartDetailGridTop .colContent').removeClass('changed');
		},5000);
	});
	
	$('body').on('click', '#fancyBoxCartProjectData .fancybox-close', function(){
		$('.cartDetailSelectedProjectFile').addClass('changed');
		
		$('.fancybox-close-small').click();
		
		setTimeout(function(){
			$('.cartDetailGridTop .colContent').removeClass('changed');
		},5000);
	});
	
	function saveOrEditNewAdress(data) {
		//console.log("1234aaa");
	   return $.ajax({
	    	url: "/saveOrEditNewAdress",
			type: "POST",
			data: data,
			success  : handleDatasaveOrEditNewAdress
	    })
	}
	
	function handleDatasaveOrEditNewAdress(response){
		$('#cartMainContainer').empty();
		$('#cartMainContainer').append(response);
		
		$('.cartDetailSelectedShippingAdress').addClass('changed');
		
		
		setTimeout(function(){
			$('.cartDetailGridTop .colContent').removeClass('changed');
		},5000);
		
		loadMuuriCart();
		
		$('.fancybox-close-small').click();
	}
	
	
	$('body').on('click', '.cartAddEmailDeleteBtn', function(e){
		e.preventDefault();
		
		var lang = $('body').attr('data-lang');
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		
		$(this).closest('.emailRow').find('input').val('');
		
	});
	
	$('body').on('click', 'idBtn', function(){
		
	});
	
	$('body').on('submit', '#formCartAddEmail', function(e){
		e.preventDefault();
		
		var lang = $('body').attr('data-lang');
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		
		var addEmail1 = $('#cartAddMail1').val();
		var addEmail2 = $('#cartAddMail2').val();
		
		if((isEmail(addEmail1) || isEmail(addEmail2)) | (addEmail1 == '' && addEmail2 == '')){
			
			var data = { cartId: cartId, addEmail1 : addEmail1, addEmail2 : addEmail2, lang : lang };				
			var ajax = changeActiveCartAddEmail(data, lang);
			
			$('.fancybox-close-small').click();
		
		}
	});
	
	function changeActiveCartAddEmail(data, lang) {
		//console.log("1234aaa");
	   return $.ajax({
		   	url: "/" + lang + "/changeActiveCartAddEmail",
			type: "POST",
			data: data,
			success  : handleDataCartReload
	    })
	}
	
	function handleDataCartReload(response){
		
		$('#cartMainContainer').empty();
		$('#cartMainContainer').append(response);
		
		$('#countElement').text($('.cartDetailGridTop').attr('data-countItems'));
		
		loadMuuriCart();
		
//		 $(".dropzone").dropzone({
//		    init: function() {
//		    	}
//		    }
//		   );
//		
		if(activeCartCommentChanged == true){
			$('.cartDetailComment').addClass('changed');
			
			activeCartCommentChanged = false;
		}
		setTimeout(function(){
			$('.cartDetailGridTop .colContent').removeClass('changed');
		},5000);
		
		if(cartItemQtyChanged == true){
			
		}
		
		
		//$('.fancybox-close-small').click();
	}
	
	$('body').on('click', '.openFancyDeliveryNote', function(){
		var txt = $(this).attr('data-droptxt');
		$('.dropZoneDeliveryNote').find('.dz-message').find('span').text(txt);	
		
		try{

			//Dropzone.discover();

		}catch(e){

		}
	});
	
	$('body').on('click', '.openFancyProjectData', function(){
		var txt = $(this).attr('data-droptxt');
		$('.dropZoneProjectData').find('.dz-message').find('span').text(txt);	
		try{

			//Dropzone.discover();

		}catch(e){

		}

	});
	
	//search for adress in fancyBox
	$('body').on('keyup paste blur', '#cartGridSearchFor', delay(function(e){
		//console.log($('#cartGridSearchFor').val());
		
		var lang = $('body').attr('data-lang');		
		var data = { lang : lang, searchedfor : $('#cartGridSearchFor').val()  };	
		
		var ajax = getFancySearchAdressList(data);
		
	} , 300));
	
	function getFancySearchAdressList (data){
		 return $.ajax({
		    	url: "/getFancySearchAdressList",
				type: "POST",
				data: data,
				success  : handleDataGetFancySearchAdressList 
		    })
	} 
	
	function handleDataGetFancySearchAdressList(response) {
		$('#cartAjaxAdressGridListContainer').empty();
		$('#cartAjaxAdressGridListContainer').append(response);
	}
	
//	$('body').on('click', '.boxSelectedShipment', function() {
//		  // in the handler, 'this' refers to the box clicked on
//		
//		var $box = $(this);
//		
//
//	  //row Select gray scale
//	  $('.selectedShipmentRow').removeClass('selectedShipmentRow');
//	  //find prev div to add selectClass
//	  $box.closest('.shipmentSelectRow').addClass('selectedShipmentRow');
//	  
//	  if ($box.is(":checked")) {
//	    // the name of the box is retrieved using the .attr() method
//	    // as it is assumed and expected to be immutable
//	    var group = ".boxSelectedShipment";
//	    // the checked state of the group/box on the other hand will change
//	    // and the current value is retrieved using .prop() method
//	    $(group).prop("checked", false);
//	    $box.prop("checked", true);
//	  } else {
//	    $box.prop("checked", false);
//	  }
//	  
//	  var lang = $('body').attr('data-lang');
//	  var cartId = $('#boxCartItemList').attr('data-activecartid');
//	  var shipmentId = $(this).attr('data-erpid');
//	  
//	  var data = { lang : lang, shipmentId : shipmentId, cartId : cartId  };
//		
//		var ajax = changeActiveCartShipmentId(data, lang);
//	  
//	});
	
	//select Cart shipment
	
	$('body').on('click', '.shipmentRowCart', function (){
		
		if(!$(this).hasClass('selectedRow')){
			$('.shipmentRowCart').removeClass('selectedRow');
			$(this).addClass('selectedRow');
			
			var shipmentId = $(this).attr('data-shipmentid');
			var lang = $('body').attr('data-lang');
			var cartId = $('#boxCartItemList').attr('data-activecartid');
			
			if($('#pickupTimeSelect').length){
				$("#pickupTimeSelect").prop("selectedIndex", 0);
			}
			
			///alert("2222");
			
			var data = { lang : lang, shipmentId : shipmentId, cartId : cartId  };
			
			var ajax = changeActiveCartShipmentId(data, lang);
		}
	});
	
	function changeActiveCartShipmentId (data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/changeActiveCartShipmentId",
				type: "POST",
				data: data,
				success  : handleDataCartReload 
		    })
	} 
	
	$('body').on('change', '#pickupTimeSelect', function(){
		//$(this).val();
		var lang = $('body').attr('data-lang');
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var selectedTimePickupTime = $('#pickupTimeSelect option:selected').val();
		
		var data = { lang : lang, selectedTimePickupTime : selectedTimePickupTime, cartId : cartId  };
		
		var ajax = changeActiveCartShipmentPickupTime(data, lang);		
	});
	
	function changeActiveCartShipmentPickupTime (data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/changeActiveCartShipmentPickupTime",
				type: "POST",
				data: data,
				success  : handleDataCartReload 
		    })
	} 
	
	//cartComment FancyBox
	var activeCartCommentChanged = false;
	
	$('body').on('blur', '#cartCommmentTextarea', function(){
		
		var oldComment = $(this).attr('data-oldval');
		
		if($(this).val() != oldComment){
			
			activeCartCommentChanged = true;
			
			var newComment = $(this).val();
			$(this).attr('data-oldval', newComment)
			
			//save newComment
			var lang = $('body').attr('data-lang');
			var cartId = $('#boxCartItemList').attr('data-activecartid');
			
			var data = { lang : lang, newComment : newComment, cartId : cartId  };
			
			var ajax = changeActiveCartComment(data, lang);
		}		
	});
	
	$('body').on('click', '#adminCartCommentDelete', function(){
		
		activeCartCommentChanged = true;
		
		var newComment = "";
		//$(this).attr('data-oldval', newComment)
		
		//save newComment
		var lang = $('body').attr('data-lang');
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		
		var data = { lang : lang, newComment : newComment, cartId : cartId  };
		
		var ajax = changeActiveCartComment(data, lang);
		
	});
	
	function changeActiveCartComment (data, lang){
		
		 return $.ajax({
		    	url: "/" + lang + "/changeActiveCartComment",
				type: "POST",
				data: data,
				success  : handleDataCartReload 
		    })
	}
	
	$('body').on('blur', '#adminInputEditShipmentCost', function(){
		var orgVal = $(this).attr('data-orgval');
		var currVal = $(this).val();
		if(currVal != orgVal){
			var lang = $('body').attr('data-lang');
			var cartId = $('#boxCartItemList').attr('data-activecartid');
			currVal = currVal.replace(',', '.');
			
			var data = { lang : lang, editShipmentCost : currVal, cartId : cartId  };
			
			$.ajax({
		    	url: "/" + lang + "/changeActiveCartEditShipmentCost",
				type: "POST",
				data: data,
				success  : handleDataCartReload 
			});
		}
		
	});
	
	$('body').on('click', '.btnItemRequestOpenChat', function(){
		$('#chatShowBtnIcoContainer').click();
		$('#chatInputTextarea').focus().val($(this).attr('data-itemno')+"\n");
	});
	
	$('body').on('click', '#itemDetailRequestPrice, .itemDetailRequestPrice, #headlineAvailabilityOnRequest, .btnItemRequestRepair', function(){
		$('#fancyBoxItemQuestionTextArea').val('');
		var txt = $(this).attr('data-txt');
		$('#fancyBoxItemQuestionTextArea').val(txt);
		var headlineTxt = $(this).attr('data-headlinetxt');
		//alert(headlineTxt);
		$('#fancyBoxItemQuestionHeadline').text(headlineTxt)
	});
	
	
	$('body').on('change', '.itemDetailSelectQty', function(){
		//alert(222);
		var thisValue = $(this).children("option:selected").attr('id')
		$(this).next('.itemDetailQtyInput').val(thisValue);
		
		if(thisValue == '10+'){
			$(this).addClass('hidden');
			$(this).next('.itemDetailQtyInput').val('10');
			$(this).next('.itemDetailQtyInput').removeClass('hidden');
			
			$(this).next('.itemDetailQtyInput').focus().select();
		}
		
		if(!$(this).hasClass('exchange') && $('#boxCartItemList').length){
			
			if($(this).attr('data-limit') > -1){
				if($(this).children("option:selected").hasClass('itemDetailQtyHigherLimit')){
						
					$('#btnShowItemLimitBox').click();
					$(this).addClass('itemQtyLimit');
					$toCartBtn = $(this).closest('.contentGrid').find('.btnItemToCart');
					$toCartBtn.removeClass('btnIconAddToCart').removeClass('addItemToCart').addClass('btnIconAddToCartExclamation').attr('data-fancybox', '').attr('data-src', '#fancyBoxItemLimit').attr('data-touch', 'false');
					
					$toOfferBtn = $(this).closest('.contentGrid').find('.btnItemToOffer');
					$toOfferBtn.removeClass('btnIconAddToOffer').removeClass('addItemToCart').addClass('btnIconAddToCartExclamation').attr('data-fancybox', '').attr('data-src', '#fancyBoxItemLimit').attr('data-touch', 'false');
					
					//get itemType and add it to button in fancybox
					var itemType = $toCartBtn.attr('data-itemtype');
					$('.callFromItemLimitBox').attr('data-itemtype', itemType);
					
					$('.itemDetailLimitQty').text(thisValue)
					
					$('#itemDetailFancyBoxLimitSpan').text(thisValue);
					$('#itemDetailFancyBoxLimitTxt').text($(this).attr('data-limittxt'));
					
				} else {
					$(this).removeClass('itemQtyLimit');
					$toCartBtn = $(this).closest('.contentGrid').find('.btnItemToCart');
					$toCartBtn.addClass('btnIconAddToCart').addClass('addItemToCart').removeClass('btnIconAddToCartExclamation').removeAttr('data-fancybox').removeAttr('data-src').removeAttr('data-touch');
					
					$toOfferBtn = $(this).closest('.contentGrid').find('.btnItemToOffer');
					$toOfferBtn.addClass('btnIconAddToOffer').addClass('addItemToCart').removeClass('btnIconAddToCartExclamation').removeAttr('data-fancybox').removeAttr('data-src').removeAttr('data-touch');
				
				};
			}
		};
	});
	
	//get qty from inputs
	$('body').on('click', '.btnIconAddToCartExclamation', function(){
		var itemType = $(this).attr('data-itemtype');
		$('.callFromItemLimitBox').attr('data-itemtype', itemType);
		
		if(itemType == 'neu'){
			$('.itemDetailLimitQty').text($('.qtyNew').val());
		}
		if(itemType == 'gebraucht'){
			$('.itemDetailLimitQty').text($('.qtyRefurbished').val());
		}
		//itemDetailLimitQty
	});
	
	
	$('body').on('change', '.itemQty', function(){
		//alert(222);
		var thisValue = $(this).children("option:selected").val()
		
		if(thisValue == '10+'){
			$(this).addClass('hidden');
			$(this).next('.itemQtyInput').removeClass('hidden');
			
			$(this).next('.itemQtyInput').focus().select();
		} else {
			
			var thisValue = $(this).val();
			var oldValue = $(this).attr('data-oldval');
			var thisField = $(this).attr('data-field');
			var itemId = $(this).attr('data-id');
			var cartId = $('#boxCartItemList').attr('data-activecartid');
			var arrIndex = $(this).attr('data-loop');
			var lang = $('body').attr('data-lang');
			
			if($(this).hasClass('itemQty')){
				thisValue = $(this).children("option:selected").val();
				
				$(this).addClass('fontBigGreen');
				cartItemQtyChanged = true;
				
				if(thisValue == '10+'){
					
					return;
				}
			
			}
			
			if (thisValue != oldValue)
			{
				
				if($(this).hasClass('itemComment')){
					$(this).addClass('fontBigGreen');
				}
					
				$('#userMessages').html($(this).attr('data-usermsg'));
				
				var pos = $(this).offset();
				var objWidth = $('.boxCartsActiveCartName').width();
				//$('.buttonEff').removeClass('btnClicked');
				showUserMessage(pos, objWidth);
					
				var data = { lang : lang, thisField : thisField, thisValue : thisValue, itemId : itemId, cartId : cartId, arrIndex : arrIndex  };
				
				var ajax = updateCartItemList(data, lang);
			
			}
			
		}
	});
	
	//cartItemListChanges
	var cartItemQtyChanged = false;
	$('body').on('blur', '.cartDetailItemList .board-item-content .itemQty, .itemQtyInput, .cartDetailItemList .board-item-content .itemComment, .cartDetailItemList .board-item-content .itemPrice, .itemType, .cartDetailItemList .board-item-content .adminBesonderheiten, .posComissionNo', function(){
		
		var thisValue = $(this).val();
		var oldValue = $(this).attr('data-oldval');
		var thisField = $(this).attr('data-field');
		var itemId = $(this).attr('data-id');
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var arrIndex = $(this).attr('data-loop');
		var lang = $('body').attr('data-lang');
		
		
		if($(this).hasClass('itemPrice')){
			
			//parseFloat(value) and value.match('^[0-9]*\.[0-9]*$')
			if(thisValue.substr(thisValue.length-3, 1) == ','){
				thisValue = thisValue.replace('.','');
			}
			if(thisValue.substr(thisValue.length-3, 1) == '.'){
				thisValue = thisValue.replace(',','');
			}
			if(thisValue.substr(thisValue.length-3, 1) != '.' && thisValue.substr(thisValue.length-3, 1) != ','){
				thisValue = thisValue.replace(',','').replace('.', '');
			}
			
			if(parseFloat(thisValue) && thisValue.match('^[0-9]*\.[0-9]*$')){
				$(this).removeClass("numberError");
			} else {
				
				$(this).val("FEHLER");
				$(this).addClass("numberError");
				$('.btnIconOrderCartArrow').prop('disabled', true);
				$('.btnIconOrderCartArrow').addClass('numberError');
				return;
			}
			
		}
		
		if($(this).hasClass('itemQty')){
			thisValue = $(this).children("option:selected").val();
			
			$(this).addClass('fontBigGreen');
			cartItemQtyChanged = true;
			
			if(thisValue == '10+'){
				
				return;
			}
		
		}
		
		if (thisValue != oldValue)
		{
			
			if($(this).hasClass('itemComment')){
				$(this).addClass('fontBigGreen');
			}
				
			$('#userMessages').html($(this).attr('data-usermsg'));
			
			var pos = $(this).offset();
			var objWidth = $('.boxCartsActiveCartName').width();
			//$('.buttonEff').removeClass('btnClicked');
			showUserMessage(pos, objWidth);
				
			var data = { lang : lang, thisField : thisField, thisValue : thisValue, itemId : itemId, cartId : cartId, arrIndex : arrIndex  };
			
			var ajax = updateCartItemList(data, lang);
		
		}
	});

	function updateCartItemList(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/updateCartItemList",
				type: "POST",
				data: data,
				success  : handleDataCartReload 
		    })
	}
	
	//sort cartItemList
	
	//check toggle drag state
	var dragToggle = false;
	
	$('body').on('click', '#cartDetailListLockToggle', function(){
		//console.log("dragy");
		if (dragToggle == false){
			dragToggle = true;
			$('.cartItemListRow').removeClass('locked');
			$('.txtSortSave').removeClass('hidden');
			$('.txtSortEnable').addClass('hidden');
		} else {
			dragToggle = false;
			
			$('.cartItemListRow').addClass('locked');
			$('.txtSortSave').addClass('hidden');
			$('.txtSortEnable').removeClass('hidden');
			
			//save new sorting
			var lang = $('body').attr('data-lang');
			var cartId = $('#boxCartItemList').attr('data-activecartid');
			
			//console.log(newSortCart);
			if (newSortCart) {
				var data = {newSortCart : newSortCart, cartId : cartId, lang : lang}
				var ajax = updateCartItemListSort(data, lang);
			}

			
		}

	});
	
	function updateCartItemListSort(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/updateCartItemListSort",
				type: "POST",
				data: data,
				success  : handleDataCartReload 
		    })
	}
	
	$('body').on('click', '#offerDetailListLockToggle', function(){
		//console.log("dragy");
		if (dragToggle == false){
			dragToggle = true;
			$('.cartItemListRow').removeClass('locked');
			$('.txtSortSave').removeClass('hidden');
			$('.txtSortEnable').addClass('hidden');
		} else {
			dragToggle = false;
			
			$('.cartItemListRow').addClass('locked');
			$('.txtSortSave').addClass('hidden');
			$('.txtSortEnable').removeClass('hidden');
			
			//save new sorting
			var lang = $('body').attr('data-lang');
			var cartId = $('#boxOfferItemList').attr('data-activeofferid');
			
			//console.log(newSortCart);
			if (newSortCart) {
				var data = {newSortCart : newSortCart, cartId : cartId, lang : lang}
				var ajax = updateOfferItemListSort(data, lang);
			}
		}

	});
 	
	function updateOfferItemListSort(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/updateOfferItemListSort",
				type: "POST",
				data: data,
				success : handleDataOfferReload 
		    })
	}
	
	//cartRenameBox Save
	$('body').on('click', '.cartRenameBoxSave', function(){
		var newName = $('#cartBoxRenameNewName').val();
		
		if (newName == ""){
			$('#cartBoxRenameNewName').addClass('inputError');
			$('.cartRenameWarning').removeClass('hidden');
		} else {
			
			var lang = $('body').attr('data-lang');
			var cartId = $('#boxCartItemList').attr('data-activecartid');
			
			var data = { lang : lang, cartId : cartId, newName : newName};
			var ajax = updateCartName(data);
		}
	});
	
	$('body').on('blur', '#adminInputChangeCartName', function(){
		//alert(222);
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var newCartName = $(this).val();
		var oldCartName = $(this).attr('data-oldname')
		var lang = $('body').attr('data-lang');
		
		if(newCartName != '' && newCartName != oldCartName){
			var data = { lang : lang, cartId : cartId, newName : newCartName};
			var ajax = updateCartName(data);
		}
		
	});
	
	function updateCartName(data){
		 return $.ajax({
		    	url: "/updateCartName",
				type: "POST",
				data: data,
				success  : handleDataUpdateCartName 
		    })
	}
	
	function handleDataUpdateCartName(response){
		location.reload()
	}
	
	//cartRenameBox Save
	$('body').on('click', '.offerRenameBoxSave', function(){
		var newName = $('#cartBoxRenameNewName').val();
		
		if (newName == ""){
			$('#cartBoxRenameNewName').addClass('inputError');
			$('.cartRenameWarning').removeClass('hidden');
		} else {
			
			var lang = $('body').attr('data-lang');
			var cartId = $('#boxOfferItemList').attr('data-activeofferid');
			
			var data = { lang : lang, cartId : cartId, newName : newName};
			var ajax = updateOfferName(data);
		}
	});
	
	
	
	function updateOfferName(data){
		 return $.ajax({
		    	url: "/updateOfferName",
				type: "POST",
				data: data,
				success  : handleDataUpdateOfferName 
		    })
	}
	
	function handleDataUpdateOfferName(response){
		location.reload()
	}
	
	//offerItemListChanges
	$('body').on('blur', '.offerDetailItemList .board-item-content .itemQty, .offerDetailItemList .board-item-content .itemComment, .offerDetailItemList .board-item-content .itemPrice', function(){
		
		var thisValue = $(this).val();
		var oldValue = $(this).attr('data-oldval');
		var thisField = $(this).attr('data-field');
		var itemId = $(this).attr('data-id');
		var cartId = $('#boxOfferItemList').attr('data-activeofferid');
		var arrIndex = $(this).attr('data-loop');
		var lang = $('body').attr('data-lang');
		
		if (thisValue != oldValue)
		{
			
		$('#userMessages').html($(this).attr('data-usermsg'));
		
		var pos = $(this).offset();
		var objWidth = $('.boxOfferActiveCartName').width();
		//$('.buttonEff').removeClass('btnClicked');
		showUserMessage(pos, objWidth);
			
		var data = { lang : lang, thisField : thisField, thisValue : thisValue, itemId : itemId, cartId : cartId, arrIndex : arrIndex  };
		
		var ajax = updateOfferItemList(data, lang);
		
		}
	});
	
	function updateOfferItemList(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/updateOfferItemList",
				type: "POST",
				data: data,
				success  : handleDataOfferReload 
		    })
	}
	
	function handleDataOfferReload(response){

		$('#offerMainContainer').empty();
		$('#offerMainContainer').append(response);
		
		//showUserMessage();
		
		loadMuuriCart();
		
	}
	
	$('body').on('click', '.cartHideColItemDesc', function(){
		//$('.colItemDesc').toggle();
		var displayFull = true; 
		
		if($('.board-column-content').hasClass('hideColDesc')){
			$(this).removeClass('btnIconPlus').addClass('btnIconMinus');
			$('.board-column-content').removeClass('hideColDesc');
			$('.colPosition').each(function(i, obj){
				var val = $(obj).text();
				val = "Pos " + val;
				$(obj).text(val);
				displayFull = true;
				//console.log($(obj).html());
			})
			
		} else {
			$(this).removeClass('btnIconMinus').addClass('btnIconPlus');
			$('.board-column-content').addClass('hideColDesc');
			$('.colPosition').each(function(i, obj){
				var val = $(obj).text();
				val = val.replace("Pos", "");
				$(obj).text(val);
				displayFull = false;
			})
		}
		
		var data = { displayFull : displayFull }
		
		$.ajax({
	    	url: "/updateCartDisplayState",
			type: "POST",
			data: data,
			//success  : handleDataUpdateOfferName 
	    })
		
		loadMuuriCart();
	});
	
	$('body').on('click', '.exchangeItem', function(){
		//get article for Exchangebox Text
		var qty = $(this).closest('.itemTypeBox').find('.qty').val();
			$('.exchangeBoxItemQty').text(qty);
		
	});
	
	var currentlyAnimating = false;
	var btnItemToCartClicked = "";
	
	//itemDetail add ItemToActiveCart
	$('body').on('click', '.addItemToCart', function(){
		
		
		btnItemToCartClicked = $(this);
		
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var lang = $('body').attr('data-lang');
		var itemType = $(this).attr('data-itemtype');
		var qty = $(this).closest('.itemTypeBox').find('.qty').val();
		var itemId = $('#itemDetailDisplay').attr('data-itemid');
		var userPreisStaffel = $('#itemDetailDisplay').attr('data-userps');
		var itemImg = $('#itemDetailDisplay').attr('data-itemimg');
		var itemNumber = $('#itemDetailDisplay').attr('data-itemname');
		var addType = 'cart';
		var repPrice = '';
		var repWarrenty = '';
		var repDelivery = '';
		
		if ($(this).hasClass('offer')){
			addType = 'offer';
			cartId = $('#boxOfferItemList').attr('data-activeofferid');			
		}

		if($(this).hasClass('dummy')){
			qty = '0';
		}
		
		//get qty if called from exchange box
		if ($(this).hasClass('callFromExchangeBox'))
		{		
			qty = $('.qtyExchange').val();		
		}
		//get qty if called from item limit box
		if ($(this).hasClass('callFromItemLimitBox'))
		{		
			if(itemType == "neu"){
				qty = $('.qtyNew').val();	
			}
			if(itemType == "gebraucht"){
				qty = $('.qtyRefurbished').val();	
			}
				
		}
		
		//if item Qty bigger then Limit => log
		if($(this).hasClass('btnIconAddToCartExclamation')){
			write2Log('Warenkorb','Artikel mit Menge größer als Limit in Warenkorb gelegt')
		}
	
		//only call not from exchange info box
		if (!$(this).hasClass('exchangeItem'))
		{
			
			if($(this).hasClass('typeRep')){
				repPrice = $(this).closest('.itemTypeBox').find('#repPrice').val().replace(",", ".");
				repWarrenty = $(this).closest('.itemTypeBox').find('#repWarrenty').val();
				repDelivery = $(this).closest('.itemTypeBox').find('#repComment').val();
				//alert(repDelivery);
				//repPrice.replace(",", ".");
			}
			
			if (qty >= 0){
				//addToCart
				
				var data = { lang : lang, cartId : cartId, itemType : itemType, qty : qty, itemId : itemId, userPreisStaffel : userPreisStaffel, itemImg : itemImg, cartType : addType, repPrice : repPrice, repWarrenty : repWarrenty, repDelivery : repDelivery }
				
				console.log("addToCart?!? " + data);
				console.log("## " + qty);
				
	// animation to cart
				$('#userMessages').html($(this).attr('data-usermsg'));
			  	
			  	if (addType == 'cart') {
			  		var ajax = addItemToCart(data, lang);
			  		var animationTarget = $('.boxCartName');
			  		
			  		var itemCount = parseFloat($('#countElement').text()) + parseFloat(qty);
			  		
			  		$('#countElement').text(itemCount);
			  		
			  	}
			  	if (addType == 'offer') {
			  		var animationTarget = $('.boxOfferName');
					var ajax = addItemToOffer(data, lang);
			  	}
			  	
			  	if ($('#itemDetailDisplay').attr('data-itemimg') != '') {
			  		
			  		var imgtodrag = $('.'+itemNumber);
				  	if (imgtodrag && currentlyAnimating == false) {
				  		
				  		currentlyAnimating = true;
		            var imgclone = imgtodrag.clone()
		                .offset({
		                top: imgtodrag.offset().top,
		                left: imgtodrag.offset().left
		            })
		             .css({
		                'opacity': '0.5',
		                    'position': 'absolute',
		                    'height': '150px',
		                    'width': '150px',
		                    'z-index': '100'
		            })
		            
		            .appendTo($('body'))
		                .animate({
		                'top': animationTarget.offset().top + 10,
		                    'left': animationTarget.offset().left + 10,
		                    'width': 75,
		                    'height': 75
		            }, 1000);
		                setTimeout(function () {
		//                $this.prop('disabled', false);
		//                $this.removeClass('addItemLoading');
		                // cart.effect("shake", {
		                    // times: 2
		                // }, 200);
		             }, 1000);
		
		            imgclone.animate({
		                'width': 0,
		                    'height': 0
		            }, function () {
		            	imgclone.detach();
		            	currentlyAnimating = false;
		            });
			  	}
			}
			  	
			}
		}
		$('.fancybox-close-small').click();
		
		return;
	});
	
	$('body').on('click', '.itemSearchNoResultToCart, .itemSearchNoResultToOffer', function(){
		//console.log(2222);

		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var addType = 'cart';
		var ajaxSuccess = addItemToCartPlaceholder;
		
		if($(this).hasClass('itemSearchNoResultToOffer')){
			cartId = $('#boxOfferItemList').attr('data-activeofferid');
			addType = 'offer';
			var ajaxSuccess = addItemToOfferPlaceholder;
		};
		
		if(cartId != ""){
		
		var lang = $('body').attr('data-lang');
		var itemType = "";
		var qty = 0;
		var itemId = $('#itemDetailDisplay').attr('data-itemid');
		var userPreisStaffel = $('#itemDetailDisplay').attr('data-userps');
		var itemImg = "";
		var itemName = $(this).attr('data-itemno');;
		var repPrice = '';
		var repWarrenty = '';
		var repDelivery = '';
		
		var data = { lang : lang, cartId : cartId, itemType : itemType, qty : qty, itemId : itemId, userPreisStaffel : userPreisStaffel, itemImg : itemImg, cartType : addType, repPrice : repPrice, repWarrenty : repWarrenty, repDelivery : repDelivery, itemName : itemName }

		var ajax = ajaxSuccess(data, lang);
		
		$('#searchResultContainer').hide();
		
		
		}
		
		
	});
	
	function addItemToCart(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/addItemToCart",
				type: "POST",
				data: data,
				success  : handleDataAddItemToCart 
		    })
	}
	
	function addItemToOffer(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/addItemToCart",
				type: "POST",
				data: data,
				success  : handleDataAddItemToOffer
		    })
	}
	
	function addItemToCartPlaceholder(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/addItemPlaceholder",
				type: "POST",
				data: data,
				success  : handleDataAddItemToCart 
		    })
	}
	
	function addItemToOfferPlaceholder(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/addItemPlaceholder",
				type: "POST",
				data: data,
				success  : handleDataAddItemToOffer
		    })
	}
	
	function handleDataAddItemToCart(response){
		$('#boxCarts').empty();
		$('#boxCarts').append(response);
		//showUserMessage($('#boxCarts'));
		
		setTimeout(function(){
			$('#boxCarts .addedItem').addClass('addedItemDelay');
		},1000);
		
		setTimeout(function(){
			$('#boxCarts .item').removeClass('addedItem').removeClass('addedItemDelay');
		},6000);
		
		//console.log(btnItemToCartClicked);
		
		var pos = btnItemToCartClicked.offset();
		var objWidth = $('.boxCartName').width();
		$('.buttonEff').removeClass('btnClicked');
		showUserMessage(pos, objWidth, 'cartItem');

	}
	
	function handleDataAddItemToOffer(response){
		$('#boxOffers').empty();
		$('#boxOffers').append(response);
		//showUserMessage($('#boxCarts'));
		
		setTimeout(function(){
			$('#boxOffers .addedItem').addClass('addedItemDelay');
		},1000);
		
		setTimeout(function(){
			$('#boxOffers .item').removeClass('addedItem').removeClass('addedItemDelay');
		},6000);
		
  		var pos = btnItemToCartClicked.offset();
		var objWidth = $('.boxOfferName').width();
		$('.buttonEff').removeClass('btnClicked');
		showUserMessage(pos, objWidth, 'cartItem');

	}
	
	//addItemToCart While no active Cart exists
	$('body').on('click', '.addItemNoCart', function(){

		if(!$(this).hasClass('callFromExchangeBox')){
		
			var lang = $('body').attr('data-lang');
			var itemType = $(this).attr('data-itemtype');
			var qty = $(this).closest('.itemTypeBox').find('.qty').val();
			var itemId = $('#itemDetailDisplay').attr('data-itemid');
			var userPreisStaffel = $('#itemDetailDisplay').attr('data-userps');
			var itemImg = $('#itemDetailDisplay').attr('data-itemimg');
			var itemNumber = $('#itemDetailDisplay').attr('data-itemname');
			var addType = 'cart';
			
//			if($(this).hasClass('dummy')){
//				itemId = $(this).attr('data-itemno');
//			}
			
			//fill hidden inputs in create cart/offer fany
			$('#fancyBoxNewCartAddItemId').val(itemId);
			$('#fancyBoxNewCartAddItemQty').val(qty);
			$('#fancyBoxNewCartAddItemType').val(itemType);
			$('#fancyBoxNewCartAddItemImage').val(itemImg);
			$('#fancyBoxNewCartAddItemPs').val(userPreisStaffel);
		}
	});
	
	//addItemToOffer While no active Offer exists
	$('body').on('click', '.addItemNoOffer', function(){
		
		if(!$(this).hasClass('callFromExchangeBox')){
		
			var lang = $('body').attr('data-lang');
			var itemType = $(this).attr('data-itemtype');
			var qty = $(this).closest('.itemTypeBox').find('.qty').val();
			var itemId = $('#itemDetailDisplay').attr('data-itemid');
			var userPreisStaffel = $('#itemDetailDisplay').attr('data-userps');
			var itemImg = $('#itemDetailDisplay').attr('data-itemimg');
			var itemNumber = $('#itemDetailDisplay').attr('data-itemname');
			//var addType = 'cart';
			
//			if($(this).hasClass('dummy')){
//				itemId = $(this).attr('data-itemno');
//			}
			
			//fill hidden inputs in create cart/offer fany
			$('#fancyBoxNewOfferAddItemId').val(itemId);
			$('#fancyBoxNewOfferAddItemQty').val(qty);
			$('#fancyBoxNewOfferAddItemType').val(itemType);
			$('#fancyBoxNewOfferAddItemImage').val(itemImg);
			$('#fancyBoxNewOfferAddItemPs').val(userPreisStaffel);
		}
	});
	
	//deleteCartItem Get ItemName For FancyBox
	$('body').on('click', '.deleteCartItem', function(){

		var itemName = $(this).attr('data-itemname');
		var uniqueId = $(this).attr('data-id');
		var arrIndex = $(this).attr('data-loop');
		
		if($(this).hasClass('activeOffer')){
			$('.cartItemDeleteBtn').addClass('activeOffer');
		}
		
		//alert(arrIndex);
		$('.cartDeleteItemBoxItemName').text(itemName);
		$('.cartItemDeleteBtn').attr('data-loop', arrIndex);
		$('.cartItemDeleteBtn').attr('data-uniqueid', uniqueId);
		
	});
	
	$('body').on('click', '.cartItemDeleteBtn', function(){
		
		var uniqueId = $(this).attr('data-uniqueid');
		var arrIndex = $('.cartItemDeleteBtn').attr('data-loop');
		var itemName = $('.cartDeleteItemBoxItemName:first').text();
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var cartType = 'cart';
		
		if ($(this).hasClass('activeOffer')) {
			cartId = $('#boxOfferItemList').attr('data-activeofferid');
			cartType = 'offer';
		}
		
		var lang = $('body').attr('data-lang');
		//var userPreisStaffel = $('#itemDetailDisplay').attr('data-userps');
		
		//alert(lang + "##" + cartId + "##" + itemName + "##" + uniqueId + "##" + arrIndex);
		$('.' + uniqueId).hide();
		
		var data = {lang : lang, itemName : itemName, arrIndex : arrIndex, cartId : cartId, uniqueId : uniqueId}
		//alert(itemName);
		var ajax = deleteItemFromActiveCart(data);	
	});
	
	function deleteItemFromActiveCart(data){
		//alert(2222);
		 return $.ajax({
		    	url: "/deleteItemFromActiveCart",
				type: "POST",
				data: data,
				success  : handleDatDeleteItemFromActiveCart 
		    })
	}
	
	function handleDatDeleteItemFromActiveCart(response) {
		//console.log(response);
		if (response.indexOf('offerDetailItemList') >= 0){
			$('#offerMainContainer').empty();
			$('#offerMainContainer').append(response);
		} else {
			$('#cartMainContainer').empty();
			$('#cartMainContainer').append(response);

			var itemCount = $(response).find('.cartDetailGridTop').attr('data-countItems');
			
			$('#countElement').text(itemCount);
		}

		loadMuuriCart();
		$('.fancybox-close-small').click();
	}
	
	
	//show CartDelete FancyBox
	$('body').on('click', '.showCartDeleteBox', function(){
		var cartId = $(this).attr('data-cartid');
		var cartName = $(this).attr('data-cartname');
		var lang = $('body').attr('data-lang');
		
		$('.fancyBoxDeleteCartName').text(cartName);
		$('.fancyBoxDeleteCartBtn').attr('data-cartid', cartId);
		$('.fancyBoxDeleteCartBtn').attr('data-cartname', cartName);
		
		$('.fancyBoxDeleteCartBtn').attr('href', '/' + lang + '/deleteCart/' + cartId);
	});
	
	//show OfferDelete FancyBox
	$('body').on('click', '.showCartDeleteBox', function(){
		var cartId = $(this).attr('data-cartid');
		var cartName = $(this).attr('data-cartname');
		var lang = $('body').attr('data-lang');
		
		$('.fancyBoxDeleteOfferName').text(cartName);
		$('.fancyBoxDeleteOfferBtn').attr('data-cartid', cartId);
		$('.fancyBoxDeleteOfferBtn').attr('data-cartname', cartName);
		
		$('.fancyBoxDeleteOfferBtn').attr('href', '/' + lang + '/deleteOffer/' + cartId);
	});
	
	//click on cart Link when no Cart is created
	$('body').on('click', '.noCartBoxLink', function(e){
		$('.boxNoCartText').addClass('adminRed');
		e.preventDefault();
		
		//alert(222);
	});
	
	$('body').on('change', '#cartShipmentUpsAccountNrCheckBox', function(){
		
		var upsCheck = "0";
		var upsNr = "";
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		
		if($('#cartShipmentUpsAccountNrCheckBox').is(':checked')) {
			$('.UserUPSAccountContainer').show();
			$('.notups').hide();
			$('.shipmentPrice').addClass('upsPriceInactive');
			
			if ($('#cartShipmentUpsAccountNr').val().length == 6){
				upsCheck = "1";
				upsNr = $('#cartShipmentUpsAccountNr').val();
			}
	
			if($('.selectedRow').hasClass('notups')){
				//reset shipment to standard
				$('.standardShipment').trigger('click');
			}
			
		} else {
			$('.UserUPSAccountContainer').hide();
			$('.notups').show();
			upsCheck = "0";
			upsNr = "";
			$('.shipmentPrice').removeClass('upsPriceInactive');
		}
		//alert(1234);
		
		var data = { upsCheck : upsCheck, upsNr : upsNr, cartId : cartId, lang : lang };
		var ajax = updateActiveCartUpsAccount(data, lang);
		
	});
	
	function updateActiveCartUpsAccount(data, lang){
		//console.log(2222);
		 return $.ajax({
		    	url: "/" + lang + "/updateActiveCartUpsAccount",
				type: "POST",
				data: data,
				success  : handleDataCartReload
		    })
	}
	
	$('body').on('click', '#showContactPeron', function(){
     	var hideContactNameState = 0;
		if ($('#showContactPeron').is(':checked')){
			$('.cartShippingContact').show();
			//alert("checked");
		} else {
			$('.cartShippingContact').hide();
			hideContactNameState = 1;
		};
		
		//$('#ajaxLoading').fadeIn(200);
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var data = { cartId : cartId, hideContactNameState : hideContactNameState}
		
		$.ajax({
			url: '/cartHideContactName',
			data: data,
			type: "POST"
		});
		
     });
	
//	function handleDataUpdateActiveCartUpsAccount(response){
//		$('#cartMainContainer').empty();
//		$('#cartMainContainer').append(response);
//	}
//	
	$('body').on('click', '.cartShipmentUpsAccountNrSave', function(){
		
		var upsCheck = "1";
		var upsNr = $('#cartShipmentUpsAccountNr').val();
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var lang = $('body').attr('data-lang');
		
		if ($('#cartShipmentUpsAccountNr').val().length != 6){
			
			upsCheck = "0";
			upsNr = "";
			$('.cartShipmentUpsNrWarning').show();
		} else {
			$('.cartShipmentUpsNrWarning').hide();
		}
		
		var data = { upsCheck : upsCheck, upsNr : upsNr, cartId : cartId, lang : lang };
		var ajax = updateActiveCartUpsAccount(data, lang);
	});
	
	$('body').on('click', '.fancyOrderChkBox', function(){
		$(this).closest('.fancyOrderCartSumRow').css('color', '#555555');
		if ($(this).is(':checked')) {
			$(this).closest('.fancyOrderCartSumRow').css('color', '#17B40B');
		}
	});
	
	var orderBtnClicked = false;
	$('body').on('click', '#cartOrderBtn', function(e){
		//e.preventDefault();
		$('.cartOrderSpin').show();
		if(orderBtnClicked == true){
			return false;
		}
		orderBtnClicked = true;
		
		//$('.fancybox-close-small').click();
	});
	
	$('#fancyBoxItemQuestionSend').click(function(){
		
		var lang = $('body').attr('data-lang');
		var msg = $('#fancyBoxItemQuestionTextArea').val();
		var email = $('#fancyBoxItemQuestionEmail').val();
		var itemName = $('#itemDetailDisplay').attr('data-itemname');
		//$('#fancyBoxItemQuestionSend .icon').removeClass('icon-itemDetailBtnOffer').addClass('icon-loading');
		$('#fancyBoxItemQuestionError').hide();
		$('#fancyBoxItemQuestionSuccess').hide();
		$('#fancyBoxItemQuestionEmail').removeClass('inputError');
		$('#fancyBoxItemQuestionTextArea').removeClass('inputError');
		$('#fancyBoxItemQuestionSuccess').hide();
		
		if (isEmail(email)) {
			if(msg != ''){
				$('#fancyBoxItemQuestionLoading').show();
				//console.log(222);
				var data = { email : email, msg : msg, lang : lang, itemName : itemName };
				var ajax = itemRequestInfo(data, lang);
			}
		}
		
		if(!isEmail(email)){
			$('#fancyBoxItemQuestionEmail').addClass('inputError');
			$('#fancyBoxItemQuestionError').show();
		}
		if (msg == ''){
			$('#fancyBoxItemQuestionError').show();
			$('#fancyBoxItemQuestionTextArea').addClass('inputError');
		}
	});
	
	function itemRequestInfo(data, lang){
		//console.log(2222);
		 return $.ajax({
		    	url: "/" + lang + "/itemRequestInfo",
				type: "POST",
				data: data,
				success  : handleDataitemRequestInfo
		    })
	}
	
	function handleDataitemRequestInfo(response){
		//console.log(response);
		if (response == 1) {
			$('#fancyBoxItemQuestionSuccess').show();
		}
	}
	
	//printCart or Offer
	$('body').on('click', '.btnPrintCart, .btnPrintOffer', function(){
		
		var url = "sendActiveCartAsEmail/cart/print"
		if ($(this).hasClass('btnPrintOffer')){
			url = "sendActiveCartAsEmail/offer/print"
		}
		
		data = "";
		
	    $.ajax({
	        type: 'POST',
	        url: url,
	        data: data,
	        //dataType: 'html',
	        success: function (response) {
	        	$(".printContent").html(response);
	        	$(".printContent").printThis({importCSS:false});  
	        },
	        error: function (data) {
	            //console.log('Error:', data);
	        }
	    });
	});
	
	//	copy to clipboard
	$('body').on('click', '.copyItemListToClipboard', function(){
		var content = $('#copyItemList').text();
		copyToClipboard('#copyItemList');
		$('.successMsgContainer').show();
	});
	
	function copyToClipboard(element) {
		var $temp = $("<textarea>");
		$("body").append($temp);
		$temp.val($(element).text().replace('<br/>' ,'\n')).select();
		document.execCommand("copy");
		$temp.remove();
	}
	
	$('body').on('click', '.printExportItemList', function(){
		$("#exportTableExcel").printThis();  
	})
	//excel export
	$('body').on('click', '.downloadExportItemListExcel', function(){
		window.open('data:application/vnd.ms-excel,' + encodeURIComponent($('.exportExcelItemList').html().replace('ü' ,'ue'))); 
	})

	$('body').on('click', '#chatShowBtn', function(){
		console.log(222);
		$(this).hide();
		$(this).removeClass('blink');
		$('#chatBoxRight').show();
		$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
		
		
	});
	
	
	$('body').on('click', '#chatHeadlineLeft', function(){
		
		$('#chatShowBtn').show();
		$('#chatBoxRight').hide();
		
			//$('#chatContentCol').toggle();
			$('#chatHeadlineLeft').toggleClass('chatHeadlineLeftActive');
			$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
		
		var chatDisplayStatus = null;
		var display = '';
		
		if($('#chatHeadlineLeft').hasClass('chatHeadlineLeftActive')) {

			chatDisplayStatus = { display : 'none' };
					
			//$('.chev').removeClass('fa-chevron-up').addClass('fa-chevron-down');
			$('#chatHeadlineLeft').removeClass('blink');
			$('#chatNewMsgText').hide();
			$('#chatHeadlineText').fadeIn(500);
			
			if($('.newMsg').length && $('#adminCheck').length == 0){
				
			setTimeout(function () {      				
				
				//ajax call set msg Read status
				var url = "/setChatMsgReadStatus"
				
				var dataArr = [];
				
//				$('.newMsg').each(function(){
//					dataArr.push($(this).attr('data-msgid'));
//				})
				
				$('.newMsg').each(function(){
					dataArr.push($(this).closest('.msgRow').attr('data-msgid'));
				})

				var data = { msgIdArr : dataArr }
				
				$('.newMsg').fadeOut(500); 
				$.ajax({
			        type: 'POST',
			        url: url,
			        data: data,

			        success: function () {

			        },
			        error: function (data) {
			           // console.log('Error:', data);
			        }
			    });
				
		}, 3000);
			}
			
		} else {
			//$('.chev').removeClass('fa-chevron-down').addClass('fa-chevron-up');
			chatDisplayStatus = { display : 'block' };
		}
		$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
		
		var containerHeight = $('#chatMsgContainer').css('height').replace('px','');
		$('.btnExpandLeft').css('top', containerHeight/2 )
		
		var url = "/updateChatDisplayStatus"
			//console.log(chatDisplayStatus);
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: chatDisplayStatus
	    });
			
	})
	
	$('body').on('mouseenter', '.cartLoopContainer', function(e){
		console.log(222);
		$(this).next('.cartOverViewBox').show();
	});
	
	$('body').on('mouseleave', '.cartLoopContainer, .cartOverViewBox', function(e){
		
		if ($('.cartOverViewBox:hover').length != 0) {
			
		} else {
		//	console.log("nope");
			$('.cartOverViewBox').hide();
		}
	});
	
	var chatDrag = null;
	
	//chat drag div
	$("#chatBox").draggable({
		start: function(event, ui) 
		{ 
			//console.log($(window).height());
			$(this).css("bottom",'auto'); 
			$(this).css("right",'auto'); 			
			
		},
		stop: function(event, ui) 
		{ 
			//check right body border
			var chatPosLeft = parseInt($(this).css('left'));
			var chatPosTop = parseInt($(this).css('top'));
			
			if(chatPosLeft + $(this).width() > $('body').width())
			{
				$(this).css('left', 'auto');
				$(this).css('right', 0);
			} 
			//check left body border
			if(chatPosLeft < 0)
			{
				$(this).css('left', 0);
				
			}
			if(chatPosTop < 0)
			{
				$(this).css('top', 0);
				
			}
			if(chatPosTop + $(this).height() + 20 > $(window).height())
			{
				$(this).css('top', 'auto');
				$(this).css('bottom', 10);
			}
			
			//save position to session
			updateChatPosToSession($(this).css('top'), $(this).css('right'), $(this).css('bottom') ,$(this).css('left'));
			
		},
	    handle: "#chatHeadline"
	}).resizable({});
	
	var containTo = $('body');
	$('#chatBox').draggable({
	  handle: '#chatHeadline',
	  containment: containTo
	}).resizable({
	  containment: containTo,
	  resize: function(e, ui) {
	        startWidth = parseInt(ui.size.width, 10);
	        heightChatBox = $('#chatBox').height();
	        heightImg = $('.chatBoxImgContainer').height();
	        heightIntputContainer = $('#chatInputContainer').height();

	        heightMsgContainer = heightChatBox - (heightImg + heightIntputContainer) - 80;
	        $('#chatMsgContainer').css('max-height', heightMsgContainer + 'px');
	        $('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
	        
	        if(heightMsgContainer > 20){
	        	$('#chatContentContainer').show();
		      //  $('.chatBoxImgContainer').css('max-height', '100px');
	        	//$('.chatBoxImgContainer').hide();
	        } else {
	        	$('#chatContentContainer').hide();
	        }
	    },
	    stop: function(e, ui){
	    	//console.log(222);
	    	updateChatPosToSession($('#chatBox').css('top'), $('#chatBox').css('right'), $('#chatBox').css('bottom') ,$('#chatBox').css('left'));
	    }
	});
	
	function updateChatPosToSession(top, right, bottom, left){
		var width = $('#chatBox').width();
		var height = $('#chatBox').height()
		var heightMsgContainer = $('#chatMsgContainer').height();
		
		
		var data = { top : top, right : right, bottom : bottom, left : left, width : width, height : height, heightMsgContainer : heightMsgContainer }
		//console.log(data);
		 return $.ajax({
		    	url: "/updateChatPosToSession",
				type: "POST",
				data: data,
			//	success  : handleDataUpdateCartName 
		    })
	}
	
	$('body').on('click', '.btnExpandLeft', function(){
		var currWidth = $('#chatBox').css('width');
		//console.log(currWidth);
		if(currWidth == '700px'){
			$('#chatBox').css('width', 350);
			$('.chevHorizontal').removeClass('fa-chevron-right');
			$('.chevHorizontal').addClass('fa-chevron-left');
		} else {
			$('#chatBox').css('width', 700);
			
			$('.chevHorizontal').removeClass('fa-chevron-left');
			$('.chevHorizontal').addClass('fa-chevron-right');
		}
		
		
	})
	
	var chatInputHeightBefore;
	
	$('body').on('focus', '#chatInputTextarea', function(){
		
		chatInputHeightBefore = $('#chatMsgContainer').height();
		
		$('#chatMsgContainer').height(270);
		$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
		$(this).height(200);
	});
	
	$('body').on('blur', '#chatInputTextarea', function(){
		
		if((event.relatedTarget && event.relatedTarget.type!="submit") || event.relatedTarget == null){
			$('#chatMsgContainer').height(chatInputHeightBefore);
			$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
			$(this).height(32);
		}
		

	});
	
//	$('body').on('click', '#chatInputTextarea', function(){
//		$('#chatMsgContainer').height(450);
//		$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
//		$(this).height(32);
//	});
	
	//chat send msg
	$('body').on('click', '#chatBtnSendMsg', function(){

		$('#chatInputTextarea').height(32);
		$('#chatMsgContainer').height(450)
		
		var msgText = $('#chatInputTextarea').val();
		var email = $(this).attr('data-use');
		var sessId = $(this).attr('data-sid');
		var lang = $('body').attr('data-lang');
		var answerAsEmail = 0;
		
		if($('#chatAnswerAsEmailCheckBox').is(':checked')){
			answerAsEmail = 1;
			if ($('#chatAnswerAsEmailCheckBox').attr('data-anon') == 'anon') {
				if($('#chatInputEmail').val() != '' && isEmail($('#chatInputEmail').val())){
					email = $('#chatInputEmail').val();
					$('#chatInputEmail').css('background-color', '#fff');
				} else {
					$('#chatInputEmail').css('background-color', 'red');
				}
			} 
		}

		if(msgText != ''){
			
			write2Log('chat nachricht','chat nachricht gesendet: ## ' + msgText)
			var url = "/sendChatMsg"
				var data = { sender : email, sessid : sessId, lang : lang, msgText : msgText, answerAsEmail : answerAsEmail };
			$('#chatInputTextarea').val('');
			 $.ajax({
		        type: 'POST',
		        url: url,
		        data: data,
		        //dataType: 'html',
		        success: function (response) {
		        	$('#chatMsgContainer').append(response);
		        	$('#chatMsgContainer').scrollTop($('#chatMsgContainer')[0].scrollHeight);
//			        	if($('.chev').hasClass('fa-chevron-down')) {
//			        		$('#chatHeadline').click();
//			        	}
		        },
		        error: function (data) {
		           console.log('Error:', data);
		        }
		    });
		}
	
	})
	
	// chat mark msg as read
	$('#chatBoxRight').on('click', function(event){
		//if($('.chev').hasClass('fa-chevron-down')) {
		//console.log(22);
		if($('.newMsg').length && $('#adminCheck').length == 0){
			//console.log(33);
			//ajax call set msg Read status
			var url = "/setChatMsgReadStatus"
			
			var dataArr = [];
			
//			$('.newMsg').each(function(){
//				dataArr.push($(this).attr('data-msgid'));
//			})

			$('.newMsg').each(function(){
				dataArr.push($(this).closest('.msgRow').attr('data-msgid'));
			})
			
			var data = { msgIdArr : dataArr }
			
			//$('.msgContainer').removeClass('newMsg');
			$('.newMsg').fadeOut(500);
			
			$('#chatHeadlineLeft').removeClass('blink');
			$.ajax({
		        type: 'POST',
		        url: url,
		        data: data,

		        success: function () {
		        	
		        },
		        error: function (data) {
		           // console.log('Error:', data);
		        }
		    });
			
		}
		//}
	});
	
	//chat checkBox answerAsEmail
	$('#chatAnswerAsEmailCheckBox').click(function(){
		if ($('#chatAnswerAsEmailCheckBox').attr('data-anon') == 'anon') {
			$('#chatInputEmailForAnswerContainer').slideToggle(500);			
		}
	});
	
	$('body').on('click', '.chatHideMsg', function(){
		
		var msgId = $(this).attr('id');
		//console.log(msgId);
		$(this).closest('.msgRow').slideUp();
		
		var data = { msgId : msgId };
		var url = "/setChatMsgHide";
		
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,

	        success: function () {
	        	
	        },
	        error: function (data) {
	           // console.log('Error:', data);
	        }
	    });
		
	
	});
	
	$('body').on('click', '.cartMenuMainBtn', function(){
		
		$('.cartMenuDropDownContainer').slideToggle(300);
	})
	
	
	$('body').on('click', '#userHistoryContainer', function(){
		
		var url = "/getUserHistory"
			
		if($('#adminCheck').length > 0){
			var url = "/getUserWeblog"
		}
		
		var displayAdmin = "1";
		
		 if($('#userHistoryHideAdmin').hasClass('notDisplayAdmin')) {
			 displayAdmin = "false";
		 }	
	
		var data = { displayAdmin : displayAdmin }
		
		$('#userHistoryContentBoxContainer').slideToggle(300);
			
		if(!$('.usHicon').hasClass('open')) {
			$('.usHicon').addClass('open');
			
			$.ajax({
		        type: 'POST',
		        url: url,
		        data: data,
	
		        success: function (response) {
		        	$('#userHistoryResultContainer').empty();
		        	$('#userHistoryResultContainer').append(response);
		        },
		        error: function (data) {
		           // console.log('Error:', data);
		        }
			});
			
		} else {
			$('.usHicon').removeClass('open');
		}
	})
	
	$('body').on('click', '.btnPrintItem', function(){
		$(".itemExportPrint").printThis();  
	})

	$('body').on('click', '.btnCopyThisItemDataToClip', function(){
		//$(".itemExportPrint").printThis();
		var content = $('#copyThisDataSheet').text();
		copyToClipboard('#copyThisDataSheet');
	});
	
	//openOfferList Comment Change
	var currOfferChangeInputVal = "";
	$('.OfferChangeInput').focus(function(){
		currOfferChangeInputVal = $(this).val();
		console.log(1234);
	});
	
	$('.OfferChangeInput').blur(function(){
		var newOfferChangeInputVal = $(this).val();
		if(currOfferChangeInputVal != newOfferChangeInputVal) {
			
			var itemId = $(this).attr('data-id');
			
			var url = "/orderItemListChangeItemComment"
			var data = { itemId : itemId, newComment : newOfferChangeInputVal }
			
			$('#userMessages').html('Änderung gespeichert');
			
			var pos = $(this).offset();
			var objWidth = $(this).width();
			//$('.buttonEff').removeClass('btnClicked');
			showUserMessage(pos, objWidth);
			
			$.ajax({
		        type: 'POST',
		        url: url,
		        data: data,
	
		        success: function (response) {
		        	//$('#userHistoryResultContainer').append(response);
		        },
		        error: function (data) {
		           // console.log('Error:', data);
		        }
			});
			
		}
		currOfferChangeInputVal = "";
	});
	
	
	var labelClicked = false;
	var checkBoxClicked = "";
	
	$('body').on('click', '#fancyBoxCartDeliveryNote label', function(){
		console.log("label body");
		labelClicked = true;
		//console.log($(this).closest(".selectDeliveryNoteContainerRow").find("input"));
		//$('.checkBoxDeliveryNote').prop('checked', false);
		//$(this).closest(".selectDeliveryNoteContainerRow").find("input").prop("checked", true);
		checkBoxClicked = $(this).closest(".selectDeliveryNoteContainerRow").find("input").prop("id");
		//$('#'+checkBoxClicked).trigger('click');
		
		//console.log("###" + checkBoxClicked);
		//$('#'+$(this).attr('for')).prop("checked", true);
		
	});
/*	
	$('.checkBoxDeliveryNote').click(function(){
		$('.checkBoxDeliveryNote').prop('checked', false);
		console.log("chkbox");
		//$(this).closest(".selectDeliveryNoteContainerRow").find("input").prop("checked", true);
		$(this).prop('checked', true);
	});
	*/
	
	$('body').on('click', '.checkBoxDeliveryNote', function(e){
		//e.preventDefault();
		$this = $(this);
		
		//console.log("chkbox body");
		
		$('.checkBoxDeliveryNote').not(this).prop('checked', false);
	/*	if(labelClicked == true){
			//$('#'+checkBoxClicked).trigger('click');
			//$('#'+checkBoxClicked).prop("checked", true);
			$('#checkBoxDeliveryNoteNeutral').prop("checked", true);
			console.log("chkbox body clicked on label " + checkBoxClicked);
			checkBoxClicked = "";
			labelClicked = false;
		} else {
			$(this).prop("checked", true);
			console.log("chkbox body clicked on chkbox " + $(this).prop("id"));
		}
		*/
		//console.log("chkbox body clicked on chkbox " + $(this).prop("id"));
		//$(this).prop("checked", true);
		//console.log("curr checkedstate ##" + $(this).prop("checked"));
		
		//$('.checkBoxDeliveryNote').prop('checked', false);
		//$(this).closest(".selectDeliveryNoteContainerRow").find("input").prop("checked", true);
		//$this.prop('checked', true);
		
		
		labelClicked = false;
	});
	
	$('body').on('change', '.checkBoxDeliveryNote', function(e){
		
		var lang = $('body').attr('data-lang');
		
		var deliveryNote = $this.val();
		var cartId = $('.selectDeliveryNoteContainer').attr('data-cartid');
		
		var url = "/" + lang + "/changeActiveCartDeliveryNote";
		var data = { cartId : cartId, deliveryNote : deliveryNote }
		
		
		
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,

	        success: handleDataCartReload
	        	//$('#userHistoryResultContainer').append(response);
	        
		});
	});
	
	$('body').on('click', '.openFancyDeliveryNote', function(){

		
		$('.fancyBillingAdress').text($('.cartDetailSelectedBillingAdress').text().trim());
		$('.fancShippingAdress').text($('.cartDetailSelectedShippingAdress').text().trim());
		
	});
	
	
	$('body').on('click', '.btnDeleteFileDelNote, .btnDeleteFileDelProjectFile' , function(){
		var path = $(this).attr('data-path');
		var index = $(this).attr('data-index');
		$('.delDeliveryNoteFilePath').val(path);
		$('.delDeliveryNoteFileIndex').val(index);
		
	});
	
	$('.contentHide').click(function(){
		$('.cartOrderSubmitContentBox').fadeOut(400);
	});
	
	$('.flashBoxHide').click(function(){
		$('.flashBox').fadeOut(400);
	});
	
	$('.cartMainContainerNoCartBoxHide').click(function(){
		$('.cartMainContainerNoCartBox').hide();
	});
	
	$('body').on('click', '.collapseBtn', function(){
		
		var boxName = $(this).attr('data-clickedbox');
		
		$('#' + boxName).toggleClass('hidden');
		var collapsed = 0;
		if($('#' + boxName).hasClass('hidden')){
			collapsed = 1;
			$(this).removeClass('far fa-minus-square');
			$(this).addClass('fas fa-plus');
		} else {
			$(this).addClass('far fa-minus-square');
			$(this).removeClass('fas fa-plus');
		}

		var url = '/updateBoxLeftCollapsedStatus';
		
		//alert(boxName + '##' + collapsed);
		
		var data = { box : boxName, collapsed : collapsed }

		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,
		});
	});
	
	$('body').on('click', '.btnIconOrderCartArrow', function(e){
		$('.posComissionNo').each(function(){
			var val = $(this).val();
			//console.log(val);
			//$(this).removeClass('inputBorderRed');
			if(val.length == 0){
				//console.log("leer");
				$(this).addClass('inputBorderRed');
				e.preventDefault();
			}
		});
	});
	
	$('body').on('click', '.cartDetailListFillItemPoSCommission', function(){
		var cartName = $(this).attr('data-cartname');
		var cartId = $('#boxCartItemList').attr('data-activecartid');
		var lang = $('body').attr('data-lang');
		var url = '/updateItemPosCommissionToCartName';
		
		//ajax
		var data = { cartId : cartId, cartName : cartName, lang : lang }

		//alert(cartId);
		//alert(cartName);
		
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,
	        success: handleDataCartReload
		});
		
		
		$('.posComissionNo').each(function(){
			$(this).val(cartName);
		});
	});
	
	$('body').on('click', '#upsBtnGetLabel', function(){
		var check = $("#upsGetLabelForm")[0].checkValidity();
		if(check == true){
			$(this).addClass('btnClicked');
		}
	});
	
	$('body').on('click', '#upsBtnLabelChangeText', function(e){
		e.preventDefault();
		var fileName = $(this).attr('data-file');
		var url = "/updateLabelFreitext";
		var addedText = $('#upsShippmentAddedInfo1').val();
		
		var data = { fileName : fileName, addedText : addedText };
		
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,
	        success: upsLabelChangeTextSuccess
		});
		
	});
	
	function upsLabelChangeTextSuccess(){
		var currFile = $('#imgUpsLabel').attr('src');
		d = new Date();
		currFile = currFile + "?" + d.getTime();
		$('#imgUpsLabel').attr('src', currFile);
		
	}
	
	$('body').on('click', '#sendUpsLabel', function(){
		var check = $("#sendUpsLabelForm")[0].checkValidity();
		if(check == true){
			$(this).addClass('btnClicked');
		}
	});
	
	$('body').on('click', '#printUpsLabel', function(){
		//{importCSS:false}
		$('#imgUpsLabel').printThis({importStyle: true});
		
		write2Log("Ups Label", "UPs Label drucken - " + $('#upsNo').val());
	});
	
	$('body').on('click', '.labelAnimated', function(){
		//console.log("23213");
		$(this).prev('input').focus();
		$(this).next('span').text($(this).attr('data-focus'));
		//$(this).text($(this).attr('data-focus'));
	});
	
	$('body').on('focus', '.inputAnimated', function(){
		$labelAnimated = $(this).next('.labelAnimated');
		$labelAnimated.next('span').text($(this).attr('data-focus'));
		//$labelAnimated.text($labelAnimated.attr('data-focus'));
	})
	
	$('body').on('blur', '.inputAnimated', function(){
		
		if(!$(this).attr('id') == 'upsShippmentAddedInfo1'){
			if($(this).val().length === 0){
				$labelAnimated = $(this).next('.labelAnimated');
				$labelAnimated.next('span').text($labelAnimated.attr('data-text'));
			}
			if($(this).hasClass('upsInput')){
				if($(this).val().length === 0){
				$labelAnimated.find('.fas').removeClass('fa-check');
				$labelAnimated.find('.fas').addClass('fa-exclamation-triangle');
			} else {
				$labelAnimated.find('.fas').removeClass('fa-exclamation-triangle');
				$labelAnimated.find('.fas').addClass('fa-check');
			}
		}
		};
	})
	
	$('body').on('blur', '#upsShippmentAddedInfo1',function(){
		write2Log("UPS-Label", "Freitext hinzugefügt - " + $(this).val());
	});
	$('body').on('blur', '#upsShippmentWeight', function(){
		write2Log("UPS-Label", "Paketgewischt geändert - " + $(this).val()) + "kg";
	});
	
	$('body').on('keyup paste', '#repairShippingItemNoInput', function(){
		
		setTimeout(function() {
		//console.log(itemFilter);
		
			//$('#repairShippingItemNoSelect').show();
			
			var itemFilter = $('#repairShippingItemNoInput').val().trim().toUpperCase();
			
			var url = "/getItemsForUpsShippingSearch";
			
			var data = { searchString : itemFilter };
			
			if(itemFilter.length >= 3){
			
				
					
					//console.log(itemFilter);
					
				$.ajax({
			        type: 'POST',
			        url: url,
			        data: data,
			        success: upsShippingItemSearchSuccess
				});
				
				
			}
		}, 300);
		
	});
	
	function upsShippingItemSearchSuccess(response){
		$('#repairShippingItemSearchResultContainer').empty();
		$('#repairShippingItemSearchResultContainer').append(response);
		$('#repairShippingItemSearchResultContainer').show();
		
	};
	
	$('body').on('click', '.upsShippingSearchResultListItem', function(){
		$('#repairShippingItemNoInput').val($(this).attr('data-itemno'));
		$('#repairShippingItemSearchResultContainer').hide();
	});
	
	$("body").click(function(){
	  $("#repairShippingItemSearchResultContainer").hide();
	});
	
	$('body').on('click', '.newRepairShipping', function(){
		var headline = $(this).attr('data-fancyheadline');
		var text = $(this).attr('data-fancytext');
		var type = $(this).attr('data-shippingtype');
		
		$('#newRepairShippingFancyBoxHeadline').text(headline);
		$('#newRepairShippingFancyBoxText').text(text);
		$('#fancyBoxNewRepairShippingType').val(type);
		
	});
	
	$('body').on('click', '#addItemToRepairShipment', function(){
		$('#repairShipmentAddItemContainer').show();
		$(this).hide();
	});
	
	$('body').on('blur', '.upsInputContainer .inputAnimated', function(){
		
		$container = $(this).closest('.posRelative');
		$nextI = $container.find('i');
		if($(this).val() == ""){
			$nextI.removeClass('fa-check');
			$nextI.addClass('fa-exclamation-triangle');
		}
		if($(this).val() != ""){
		
			$nextI.removeClass('fa-exclamation-triangle');
			$nextI.addClass('fa-check');
		}
	});
	
//	$('body').on('blur', '#repairShippingItemNoInput', function(){
//		$('#repairShippingItemSearchResultContainer').hide();
//	});
	
	
//	$("#fontMinus").on('mousewheel', function(e){
//		  console.log(e);
//		 
//		});
//	
//		var event = jQuery.Event( "mousewheel" );
//		event.deltaY = -1;
//		$(".test").trigger(event);  
//		
//		$("#fontMinus").on('click', function(e){
//			
//		});
//		document.getElementById("fontMinus").dispatchEvent(new MouseEvent("mousewheel", {ctrlKey: true}));
		
		
	//dropzone file drag hover
	$(document).bind('dragover', function (e) {
	    var dropZones = $('.dropzone'),
	        timeout = window.dropZoneTimeout;
	    if (timeout) {
	        clearTimeout(timeout);
	    } else {
	        dropZones.addClass('in');
	    }
	    var hoveredDropZone = $(e.target).closest(dropZones);
	    dropZones.not(hoveredDropZone).removeClass('hover');
	    hoveredDropZone.addClass('hover');
	    window.dropZoneTimeout = setTimeout(function () {
	        window.dropZoneTimeout = null;
	        dropZones.removeClass('in hover');
	    }, 100);
	});
	
//	$('body').click(function(event){
//		console.log(event.target.nodeName);
//	});
	
	//	$('body').click(function(){
//		console.log("bodyc");
//	});
	// When the user scrolls the page, execute myFunction 
	window.onscroll = function() {StickyNav()};
	//window.onscroll = function() {StickyBarLeft()};

	// Get the navbar
	var navbar = document.getElementById("fixedsticky");
	
	var barContentLeft = document.getElementById("leftContainer");

	// Get the offset position of the navbar
	var sticky = navbar.offsetTop;
	var barLeftSticky = barContentLeft.offsetTop;

	// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
	
	var topHeaderZoomed = false;
	
	//console.log($(document).width());
	
	function StickyNav() {
//		console.log(navbar.offsetTop);
//		console.log(window.pageYOffset);
		if (window.pageYOffset >= sticky) {
	 // if (window.pageYOffset > 0) {
		  
		//$('#headerTopContent').addClass("stickyHeaderTop");
		//$('#headerTopContent').addClass('headerTopContentAnimate');
	    navbar.classList.add("sticky")
	   
//	    if(topHeaderZoomed == false){
//	    	$('#headerTopContent').animate({zoom: '60%'}, "slow");
//	    	topHeaderZoomed = true;
//	    }
	    
	  } else {
		  
		  console.log("not Sticky");
		  //$('#headerTopContent').animate({zoom: '100%'}, "slow");
		  $('#headerTopContent').animate({zoom: '100%'}, "slow");
		  topHeaderZoomed = false;
	    navbar.classList.remove("sticky");
	    
	    //$('#headerTopContent').removeClass("stickyHeaderTop");
	  }

	  //console.log(window.pageYOffset);
	  
//	  if (window.pageYOffset >= 400) {
//		  
//		  barContentLeft.classList.add("stickyContentLeft")
//	  } else {
//		  barContentLeft.classList.remove("stickyContentLeft");
//	  }
	  
//	  var top = ($(window).scrollTop());
//	  //console.log(top);
//		var leftBoxOffset = $('#leftContainer').offset();
//		//console.log(leftBoxOffset);
//		var breakPoint = ($('#contentMain').height() - $('#leftContainer').height()) + $('#contentMain').offset().top - 100;
		//console.log(breakPoint);
//		if(debug) {
//			$('#debug').html(top + ' / ' + ($('#contentLeft').offset().top + $('#contentLeft').height()) + ' / ' + ($('#contentCenterMargin').offset().top + $('#contentCenterMargin').height()));
//		}
//		if($('#contentMain').height() > $('#leftContainer').height()) {
//			if(top > 450) {
//				if(top >= breakPoint) {
//					$('#leftContainer').css({position: 'absolute', left: 0, top: '', margin: 0, float: 'none', bottom: 0});
//				} else {
//					$('#leftContainer').css({position: 'fixed', left: leftBoxOffset.left, top: 105, margin: 0, float: 'none', bottom: ''});
//				}
//			} else {
//				 $('#leftContainer').css({position: '', left: '', top: '', margin: '', float: '', bottom: ''});
//			}
//		}
	  	  
	}
	
	//qty buttons
	function wcqib_refresh_quantity_increments() {
	    jQuery("div.quantity:not(.buttons_added), td.quantity:not(.buttons_added)").each(function(a, b) {
	        var c = jQuery(b);
	        c.addClass("buttons_added"), c.children().first().before('<input type="button" value="-" class="minus" />'), c.children().last().after('<input type="button" value="+" class="plus" />')
	    })
	}
	String.prototype.getDecimals || (String.prototype.getDecimals = function() {
	    var a = this,
	        b = ("" + a).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
	    return b ? Math.max(0, (b[1] ? b[1].length : 0) - (b[2] ? +b[2] : 0)) : 0
	}), jQuery(document).ready(function() {
	    wcqib_refresh_quantity_increments()
	}), jQuery(document).on("updated_wc_div", function() {
	    wcqib_refresh_quantity_increments()
	}), jQuery(document).on("click", ".plus, .minus", function() {
		
	    var a = jQuery(this).closest(".quantity").find(".qty"),
	        b = parseFloat(a.val()),
	        c = parseFloat(a.attr("max")),
	        d = parseFloat(a.attr("min")),
	        e = a.attr("step");
	    b && "" !== b && "NaN" !== b || (b = 0), "" !== c && "NaN" !== c || (c = ""), "" !== d && "NaN" !== d || (d = 0), "any" !== e && "" !== e && void 0 !== e && "NaN" !== parseFloat(e) || (e = 1), jQuery(this).is(".plus") ? c && b >= c ? a.val(c) : a.val((b + parseFloat(e)).toFixed(e.getDecimals())) : d && b <= d ? a.val(d) : b > 0 && a.val((b - parseFloat(e)).toFixed(e.getDecimals())), a.trigger("change")
	    
	    $this = $(this).closest(".quantity").find(".qty");
	    if($this.val() != "1"){
	    	 $this.addClass("qtyChanged");
	    }
	   	 else 
	   	 {
	   		 $this.removeClass("qtyChanged"); 
	   	 }
	});
	
	//cartListDetail
	
//	$('.cartDetailItemList').on('click', '.itemComment', function(){
//		console.log(1234);
//	});
	
	
	//close searchBox if opened
	$('html').click(function(e) {
		  //Hide the menus if visible
		
			if(!$(e.target).hasClass('searchInputGo') && !$('div#searchResultContainer').has($(e.target)).length){
				
				if($('#searchResultContainer').is(':visible')){
					
					if($('#searchResultGrid .resultName').length < 5 && $('#searchResultGrid .resultName').length > 0){
						var logData = [];
						$('#searchResultGrid .resultName').each(function(e){
							//alert($(this).attr('data-itemname'));
							var price = '';
							
							if($(this).hasClass('noPrice')){
								price = 'ohne Preis';
							}
							
							var itemName = $(this).attr('data-itemname')
							
							var itemdata = { itemname : itemName, noprice : price}
							logData.push(itemdata);
						});
						var url = "/searchCloseToLog";
						
						console.log(logData);
						
						var data = { item : logData };
						
						$.ajax({
					        type: 'POST',
					        url: url,
					        data: data,

					        success: function () {

					        },
					        error: function (data) {
					           // console.log('Error:', data);
					        }
					    });
						
					}
					
					$('#searchResultContainer').slideToggle(200);
				}
			}

			if(!$(e.target).hasClass('userHistoryContainer') && !$('div#userHistoryContentBoxContainer').has($(e.target)).length && !$('div#userHistoryContainer').has($(e.target)).length){

				if($('.usHicon ').hasClass('open')){

					$('#userHistoryContentBoxContainer').hide(200);
					$('.usHicon ').removeClass('open');
				}
			}
			if(!$(e.target).hasClass('cartMenuClass')){
				$('.cartMenuDropDownContainer').hide();
			}
			
		
	});
	
	
	function isEmpty( el ){
	      return !$.trim(el.html())
	  }
	
	var mainInputPlaceholder = $('#mainSearchInput').attr('placeholder');
	$('body').on('focus', '#mainSearchInput', function(e) {
		$('#mainSearchInput').attr('placeholder', '');		
	});
	
	$('body').on('blur', '#mainSearchInput', function(e) {
		$('#mainSearchInput').attr('placeholder', mainInputPlaceholder);		
	});
	
	$('body').on('paste', '#mainSearchInput', function(e) {
		setTimeout(function(){
			e = $.Event('keyup');
			e.keyCode= 13; // enter
			$('.searchLoadingIcon').show();
			$('#mainSearchInput').trigger(e);
		},100);
	});
	
	$('#mainSearchInput').click(function(event){
		if(!$('#searchResultContainer').is(':visible')){
			if (!isEmpty($('#searchResultContainer'))) {
				$('#searchResultContainer').slideToggle(200);
			} else {
				var lang = $('body').attr('data-lang');
				var searchStringTerm = $('#mainSearchInput').val();		
				if (searchStringTerm != '') {			
					var data = { searchString: searchStringTerm, lang: lang };						
					var ajax = getDataSearch(data, lang);
				}
			}			
		}
		if($('.usHicon ').hasClass('open')){
			$('#userHistoryContentBoxContainer').hide(200);
			$('.usHicon ').removeClass('open');
		}
	    event.stopPropagation();
	});
	
	//clear search input 
	$('.searchInputClear').click(function(){
		$('#mainSearchInput').val('').focus();
		
	});
	
	//open search Result clicked on btn
	$('.searchInputGo').click(function(){
		if(!$('#searchResultContainer').is(':visible')){
			if (!isEmpty($('#searchResultContainer'))) {
				$('#searchResultContainer').slideToggle(200);
			} else {
				var searchStringTerm = $('#mainSearchInput').val();		
				var lang = $('body').attr('data-lang');
				if (searchStringTerm != '') {			
					var data = { searchString: searchStringTerm, lang: lang };						
					var ajax = getDataSearch(data, lang);
				}
			}	
		}
		var email = $('#userMail').text();
		var searchStringTerm = $('#mainSearchInput').val();	
		
		
		setTimeout(getSearchResultLength, 1000)

	});
	
	function getSearchResultLength(){
		var email = $('#userMail').text();
		var searchStringTerm = $('#mainSearchInput').val();	
		
		if($('.resultName').length == 0 && email != ''){
			//send email for items not in db 
			
			var data = { searchString: searchStringTerm, email: email, lang: lang };
			//console.log("### no searchresult" + email );
			sendEmailNoItemInDb(data, lang);
		}
	}
	
	//
	
	function sendEmailNoItemInDb(data, lang) {
		//console.log(data);
		//alert(lang);
	   return $.ajax({
	    	url: "/" + lang + "/sendEmailNoItemInDb",
			type: "POST",
			data: data,
			success  : "" 
	    })
	}
	
	var lastSearchResultHoveredId = "";
	
	$('body').on('mouseover','#searchResultGrid .item', function(){
		
		$('.item').removeClass('hoverThis');
		
		
		if(!$(this).hasClass('resultItemType') && !$(this).hasClass('resultPrice')){
			$(this).addClass('hoverThis');
			if(!$(this).hasClass('resultPrice')){
				$('.divPrice').removeClass('hoverThis');
			}
		}
		if(lastSearchResultHoveredId != $(this).attr('data-id')) {
			$('.item').removeClass('hovered');
			lastSearchResultHoveredId = $(this).attr('data-id');
			$('.i'+lastSearchResultHoveredId).toggleClass('hovered');
		}
	});
	
	$('body').on('mouseover', '.divPrice', function(){
			
		$('.divPrice').removeClass('hoverThis');
		$(this).addClass('hoverThis');
		//alert(222);
	});
	
	//click searchResult Container
	$('#mainSearchRight').on('click', '.item', function(){
		var $link = $('.l' + $(this).attr('data-id'));
		$link[0].click();
		$('#searchResultContainer').slideUp(200);
		$('.loading').show();
	});
	
	//login visible
	$(".toggle-password").click(function() {

	  $(this).toggleClass("fa-eye fa-eye-slash");
	  var input = $($(this).attr("toggle"));
	  if (input.attr("type") == "password") {
	    input.attr("type", "text");
	  } else {
	    input.attr("type", "password");
	  }
	});

	$('body').on('change', '.goog-te-combo', function(){
		var optionSelected = $("option:selected", this);
	    var valueSelected = this.value;
	    
	    if(valueSelected == 'en' | valueSelected == 'de'){
	    	$('#\\:1\\.container').contents().find('#\\:1\\.restore').click();
	    }
	    
	    write2Log("Google Sprachänderung", "Sprache auswählen - " + optionSelected.text());
	    
	    //alert(optionSelected.text());
	    
	});
	
	$('.flagLangSelect').click(function(){
		//reset session var googleLang //resetGoogleLand
		var url = "/resetGoogleLand"
		$.ajax({
  	        url: url
  	      // data: data,

  	        	//$('#userHistoryResultContainer').append(response);
  	        
  		});
		
		$('#\\:1\\.container').contents().find('#\\:1\\.restore').click();
	});
	
	$('#registrationForm').on('keydown', 'input', function(e){
//		if ( event.which == 13 ) {
//			event.preventDefault();
//		}
		
		if( (event.keyCode == 13) && (validationFunction() == false) ) {
			event.preventDefault();
			return false;
	    }
	
		//e.preventDefault();
	});

	//registration copy Arrow
	$('.copyArrow').click(function(){
		if($(this).hasClass('copyPhone')){
			$('#registrationFormContactPhone').val($('#registrationFormCompanyPhone').val());
			if($('#registrationFormContactPhone').val().length > 2){
				$('#registrationFormContactPhone').addClass('filled');
			} else {
				$('#registrationFormContactPhone').removeClass('filled');
			}
		}
		if($(this).hasClass('copyFax')){
			$('#registrationFormContactFax').val($('#registrationFormCompanyFax').val());
		}
		if($(this).hasClass('copyEmail')){
			$('#registrationFormContactEmail').val($('#registrationFormCompanyEmail').val());
			if($('#registrationFormContactEmail').val().length > 2){
				$('#registrationFormContactEmail').addClass('filled');				
			} else {
				$('#registrationFormContactEmail').removeClass('filled');
			}
		}
	});
	
	$('#registrationContainer').on('keyup', 'input, textarea', function(){
		$this = $(this);
		if($this.attr('required') == 'required'){
			if($this.val().length > 2){
				$this.addClass('filled');				
			} else {
				$this.removeClass('filled');
			}
		}
		
	});
	
	$('#registrationContainer').on('blur', 'input, textarea', function(){
		//console.log(222);
		$this = $(this);
		if($this.attr('required') == 'required'){
			
			if($this.val().length > 0){
				//console.log(222);
				$this.addClass('filled');				
			} else {
				$this.removeClass('filled');
			}			
		}
		
	});
	
	$('#registerFormatFields').click(function(){
		var val = '';
		
		$.each($('#registrationContainer input'), function(i,e) {
			
			if (!$(e).hasClass('noFormat')){
				
				val = $(e).val().trim();
				$(e).val(val);
				if ($(e).hasClass('numberFormat')){	
					val = $(e).val().replace('(0)','');
					val = val.replace('+49','0');
					val = val.replace('0049','0');
					val = val.replace(/\D/g,'');
					$(e).val(val);  
				}
			}
			     
		});
	});
	
	function formSubmit(response) {
	    frm.submit();
	}
	
	$('body').on('click', '.capChkBoxDiv' ,function(e){
		
		var $img = $(this).find('img');
		var $label = $(this).find('label');
		var $chkBox = $(this).find('input');
		var $chkIcon = $(this).find('.capImgCheck');
		
		if($chkBox.is(':checked')){
			$img.addClass('imgActive');
			$chkIcon.show();
			
			if($chkBox.attr('name') != 'capImg2' && $chkBox.attr('name') != 'capImg4' && $chkBox.attr('name') != 'capImg5'){
				$img.addClass('imgActive2');
				//alert($chkBox.attr('name'));
			}
			
		} else {
			$img.removeClass('imgActive');
			$img.removeClass('imgActive2');
			$chkIcon.hide();
		}	
	});

	
	function chckForm($currForm){
		var test1 = '';
		
		$currForm.find('.capChkBoxDiv').each(function(index){
			test1 = test1 + $(this).find('input').prop('checked');
			//console.log(test1)
		});
		
//		$('.captchaContainer').find('.capChkBoxDiv').each(function(index){
//			test1 = test1 + $('.captchaContainer').find('input').prop('checked');
//		});
		
		//console.log(test1);
		
		if (test1 == 'falsetruefalsetruetruefalse'){
			write2Log('robot check', 'roboter erfolgreich')
			//console.log(test1)
			return true;
		}
		write2Log('robot check', '##' + test1 +  ' roboter fehler')
		
		return false;
		
	}
	
	$('#formCallBack').submit(function() {
		
		
		
		if($('.captchaContainer').length) {
		
			$('.txtRobot').removeClass('error');
			
			var chckFormResponse = chckForm($(this));
			
			if(chckFormResponse == false) {
				
				$('.txtRobot').addClass('error');
				$('.imgCap').removeClass('imgActive');
				$('.imgCap').removeClass('imgActive2');
				$('.capImgCheck').hide();
				$('.capChkBox').prop('checked', false);
				$('.buttonEff').removeClass('btnClicked');
				$('.capImg').prop('checked', false);
				
				event.preventDefault();
			} else {
				$('#formCallBack .buttonEff').addClass('btnClicked');
			}
		
		}
	});
	
	$('#contactForm, #registrationForm').submit(function() {

		if($('.captchaContainer').length) {
		
			$('.txtRobot').removeClass('error');
			
			var chckFormResponse = chckForm($('#fancyBoximgCap'));
			console.log(chckFormResponse);
			if(chckFormResponse == false) {
				
				//$('.txtRobot').addClass('error');
				$('.imgCap').removeClass('imgActive');
				$('.imgCap').removeClass('imgActive2');
				$('.capImgCheck').hide();
				$('.capChkBox').prop('checked', false);
				$('.buttonEff').removeClass('btnClicked');
				$('.capImg').prop('checked', false);
				
				
				event.preventDefault();
			} else {
				$('.captchaContainer .buttonEff').addClass('btnClicked');
				console.log("true cliked");
			}
		
		}
	});

	$('body').on('click' ,'.faqHeadline', function(){
		$('.faqHeadline').removeClass('active');
		
		var clickedOnOpen = false;
		
		if($(this).next('.faqContent').is(":visible")){
			var clickedOnOpen = true;
		}
		
		$('.faqContent:visible').slideToggle();
		
		//alert(1234);
		if(clickedOnOpen == false) {
			$(this).next('.faqContent').slideToggle();
			$(this).addClass('active');
		}
	});
	
	$('body').on('click', '#contactFormBtnValidate', function(){
			
		    if ($("#contactForm")[0].checkValidity()){
		    	
		    	if($(this).hasClass('loggedIn')){
		    		$('#contactFormSubmit').click();
		    	} else {
		    		$('#contactFormBtnOpenimgCap').click();
		    	}
		        //alert('sucess');
		    } else {
		        //Validate Form
		    	
		    	$("#contactForm")[0].reportValidity()
		    }
		    
		    $('.txtRobot').removeClass('error');
		
		//$('.captchaContainer').show();
	});
	
	$('body').on('click', '#contactFormBtnSend', function(){
		$('#contactFormSubmit').click();
	});
	
	$('body').on('click', '#registerFormBtnValidate', function(){
		
		console.log("Test start");
		
	    if ($("#registrationForm")[0].checkValidity()){
	    	
	    	console.log("Test 2 # " + $("#registrationForm")[0].checkValidity());
	    	
	    	if($(this).hasClass('loggedIn')){
	    		$('#registerFormSubmit').click();
	    	} else {
	    		$('#registerFormBtnOpenimgCap').click();
	    		console.log("Test 3 # " );
	    	}
	        //alert('sucess');
	    } else {
	        //Validate Form
	    	
	    	$("#registrationForm")[0].reportValidity()
	    	
	    	console.log("Test  # " );
	    }
	    
	    $('.txtRobot').removeClass('error');
	});
	
	$('body').on('click', '#registerFormBtnSend', function(){
		
		$('#registerFormSubmit').click();
	});
	
	$('body').on('click', '.capChkBoxLabel', function(){
		var fromForm = $(this).attr('for');
		var clickedCapImg = $(this).attr('data-capimg');
		// check which from
		if(fromForm.indexOf("contact") >=0){
			$('#contactFormCapImg' + clickedCapImg).click();
		}
		if(fromForm.indexOf("register") >=0){
			$('#registerFormCapImg' + clickedCapImg).click();
		}
		
	});
	
	$('body').on('click', '.deleteRepairShipment', function(){
		var shipmentId = $(this).attr('data-id');
		var shipmentname = $(this).attr('data-name');
		
		$('#fancyDeleteShipmentname').text(shipmentname);
		$('#fancyBoxDeleteShipmentId').val(shipmentId);
	});
	
	
	//repairShipmentItemListChanges
	var repairShipmentItemQtyChanged = false;
	$('body').on('blur', '.rowGridRepairShipment .repShipmentItemQtyIput, .rowGridRepairShipment .repShipmentItemQty, .rowGridRepairShipment .repShipmentItemName, .rowGridRepairShipment .repShipmentItemComment, .rowGridRepairShipment .repShipmentPosComissionNo', function(){
		
		var thisValue = $(this).val();
		var oldValue = $(this).attr('data-oldval');
		var thisField = $(this).attr('data-field');
		var shipmentId = $('.repShipItemBoard').attr('data-shipmentId');
		var arrIndex = $(this).attr('data-loop');
		var lang = $('body').attr('data-lang');
		
		if($(this).hasClass('repShipmentItemQtyIput')){
			thisValue = $(this).children("option:selected").val();
			
			$(this).addClass('fontBigGreen');
			cartItemQtyChanged = true;
			
			console.log("22");
			
			if(thisValue == '10+'){
				
				return;
			}
		
		}
		
		if (thisValue != oldValue)
		{
			
			if($(this).hasClass('itemComment')){
				$(this).addClass('fontBigGreen');
			}
				
			$('#userMessages').html($(this).attr('data-usermsg'));
			
			var pos = $(this).offset();
			var objWidth = $('.boxCartsActiveCartName').width();
			//$('.buttonEff').removeClass('btnClicked');
			showUserMessage(pos, objWidth);
				
			var data = { lang : lang, thisField : thisField, thisValue : thisValue, shipmentId : shipmentId, arrIndex : arrIndex  };
			
			console.log(data);
			
			var ajax = updateShipmentItemList(data, lang);
		
		}
	});

	function updateShipmentItemList(data, lang){
		 return $.ajax({
		    	url: "/" + lang + "/updateShipmentItemList",
				type: "POST",
				data: data,
				success  : "" 
		    })
	}
	
	$('body').on('click', '.deleteRepShipmentItem', function(){
		var shipmentId = $('.repShipItemBoard').attr('data-shipmentId');
		var itemArrIndex = $(this).attr('data-loop');
		var itemName = $(this).attr('data-itemname');
		
		$('#fancyBoxDeleteItemShipmentId').val(shipmentId);
		$('#fancyBoxDeleteItemShipmentItemArrIndex').val(itemArrIndex);
		$('#fancyBoxDeleteItemItemName').text(itemName);
	});
	
	$('body').on('click', '#adminCartDetailErpInfoTableSave', function(){
		
		var cartID = $('#cartInputPrio').attr('data-cartid');
		var cartInputOrt = $('#cartInputOrt').val();
		var cartInputKistenID = $('#cartInputKistenID').val();
		var cartInputBesorgen = $('#cartInputBesorgen').val();
		var cartInputTesten = $('#cartInputTesten').val();
		var cartInputBearbeiter = $('#cartInputBearbeiter').val();
		var cartInputVerpackungsart = $('#cartInputVerpackungsart').val();
		
		var data = {cartID : cartID, cartInputOrt : cartInputOrt, cartInputKistenID : cartInputKistenID, cartInputBesorgen : cartInputBesorgen, cartInputTesten : cartInputTesten, cartInputBearbeiter : cartInputBearbeiter, cartInputVerpackungsart : cartInputVerpackungsart};
		
		
		var url = "/admin/adminCartDetailErpInfoTableSave";
		
		
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,

	        success: handleDataCartReload,
	        error: function (data) {
	           // console.log('Error:', data);
	       }
	    });
	});
	
	$('body').on('click', '#adminCartDetailErpInfoTableShowAllItemPos', function(){
		var loop = $(this).attr('data-loop');
		$('.adminItemListPosErpTable').toggle();
		
		loadMuuriCart();
	});

	loadMuuriCart();
	
	$('body').on('click', '.loadMuusriTest', function(){
		loadMuuriCart();
	});
		
	function loadMuuriCart(){
			
		//check if sortable grids are on page
		if ( $( ".board-column-content" ).length ) {
					
				//grids
				
				//boards
			
			var itemContainers = [].slice.call(document.querySelectorAll('.board-column-content'));
			var columnGrids = [];
			var boardGrid;
		
			// Define the column grids so we can drag those
			// items around.
			itemContainers.forEach(function (container) {

			  // Instantiate column grid.
			  var grid = new Muuri(container, {
			    items: '.board-item',
			    layoutDuration: 400,
			    layoutEasing: 'ease',
			    dragEnabled: true,
			    dragAxis: 'y',
			    dragSort: function () {
			      return columnGrids;
			    },
			    dragSortInterval: 0,
			    dragContainer: document.body,
			    dragReleaseDuration: 400,
			    dragReleaseEasing: 'ease',
			    dragStartPredicate: function (item, e) {

			    	if (dragToggle == true) {
				        // For other items use the default drag start predicate.
				        return Muuri.ItemDrag.defaultStartPredicate(item, e);
			    	}
			      }
			  })

			  .on('dragReleaseEnd', function (item) {
			    // Let's remove the fixed width/height from the
			    // dragged item now that it is back in a grid
			    // column and can freely adjust to it's
			    // surroundings.
			    item.getElement().style.width = '';
			    item.getElement().style.height = '';
			    // Just in case, let's refresh the dimensions of all items
			    // in case dragging the item caused some other items to
			    // be different size.
			    columnGrids.forEach(function (grid) {
			      grid.refreshItems();
			      //console.log(item);
			      newSortCart = grid.getItems().map(item => item.getElement().getAttribute('data-loop'));
			     // console.log(newSortCart);
			    });
			  })
			  .on('layoutStart', function () {
			    // Let's keep the board grid up to date with the
			    // dimensions changes of column grids.
			    boardGrid.refreshItems().layout();
			  });
		
			  // Add the column grid reference to the column grids
			  // array, so we can access it later on.
			  columnGrids.push(grid);
			});
			
			// Instantiate the board grid so we can drag those
			// columns around.
			boardGrid = new Muuri('.board', {
			  layoutDuration: 400,
			  layoutEasing: 'ease',
			  dragEnabled: false,
			  dragSortInterval: 0,
			  dragStartPredicate: {
			    handle: '.board-column-header'
			  },
			  dragReleaseDuration: 400,
			  dragReleaseEasing: 'ease'
			});
			
			pushFooter();
		}
		
		
	}

	
	$(".dropzone, .dropzone1").dropzone({
		success: function() {
			
    	  	
			//var data = { cartId : cartId, deliveryNote : deliveryNote }
	  		if(!$('.dropzone').hasClass('filet')){
	  			var lang = $('body').attr('data-lang');
	    	  	var url = "/" + lang + "/reloadCartDetailBox";
	  			
	  			$.ajax({
		  	        type: 'POST',
		  	        url: url,
		  	      // data: data,
		
		  	        success: handleDataCartReload,
		  	        error: function(){
		  	        	console.log("dz error error dsadasd");
		  	        }
		  	        	//$('#userHistoryResultContainer').append(response);
		  	     
		  		});
	  		}
		},
		error: function(){
			console.log("dz error");
			 $(file.previewElement).find('.dz-error-message').text(response);
		}
	});
		
	var x = null;
	var y = null;
	
	function getMousePos(e){
		x = e.pageX;
   		y = e.pageY;  		
	}
	
	function getMouseX() {
   		return x;
	}
	
	function getMouseY() {
	    return y;
	}
	
	function getScreenWidth(){
		var screenWidth = $(document).width();
		return screenWidth;
	}
	function getScreenHeight(){
		var screenHeight = window.innerHeight;
		return screenHeight;
	}
//	
	if($('#windowRes').attr('data-windowres') == ''){
		var width = getScreenWidth();
		var height = getScreenHeight();
		var data = { width : width, height : height };
		
		var url = "/windowResSession";
		
		$.ajax({
  	        type: 'POST',
  	        url: url,
  	       data: data,

  	        success: ''
  	        	//$('#userHistoryResultContainer').append(response);
  	        
  		});
		
		//$('#windowRes').attr('data-windowres', resString)
		
		//alert(width + "##" + height);
	}
	
	var userMsgShowing = false;
	
	function showUserMessage(pos, objwidth, cartItems, width){
		$('#userMessages').stop();
		$('#userMessages').hide();
		var screenWidth = getScreenWidth();
		if (cartItems == 'cartItem') {
			var mousePosX = pos.left;
			var mousePosY = pos.top + 23;
			var posLeft = parseInt(mousePosX);
			$("#userMessages").css({position:"absolute", left:posLeft,top:mousePosY});
			$("#userMessages").css('zIndex', 30);
			//console.log(mousePosX + "##" + mousePosY);
		} else {
		
			if (pos) {
				var mousePosX = pos.left;
				var mousePosY = pos.top;
				var posLeft = parseInt(mousePosX+objwidth+10);
				$("#userMessages").css({position:"absolute", left:pos.left,top:pos.top+33});
				$("#userMessages").css('zIndex', 30);
				//console.log(pos.left);
			} else { 
				var mousePosX = getMouseX();
				var mousePosY = getMouseY();
				$("#userMessages").css({position:"absolute", left:mousePosX,top:mousePosY});
				$("#userMessages").css('zIndex', 30);
				//console.log(123);
			}
		}
		if (mousePosX + 350 > screenWidth){
			mousePosX = screenWidth - 400;
			//console.log(1234);
		}
		if (width){
			$("#userMessages").width(width);
			//console.log(12345);
		}
		if (userMsgShowing == false){
			$('#userMessages').css('display','block');
			userMsgShowing = true;
			$('#userMessages').animate({'height':20},300).animate({boxShadow: '0 0 8px #fffcd4'}).delay(1000).animate({'height':0},300,function(){
				$('#userMessages').hide(200);
				userMsgShowing = false;
			});	
		}
	}
	
	function resizeFunction() {

//		$('#contentLeft').css({position: '', left: '', top: '', margin: '', float: '', bottom: ''});
//		$(window).scroll();
//		pushFooter();
	}

	//push footer
	function pushFooter() {
		var heightCorrection = 0;
		if ($('html').hasClass('oldie')) {
			heightCorrection = 10;
		}
		$('#footer').css({
			'position' : '',
			'bottom' : ''
		});
		if ($(document).height() > $(window).height() + heightCorrection) {
			$('#footer').css({
				'position' : '',
				'bottom' : ''
			});
		} else {
			$('#footer').css({
				'position' : 'fixed',
				'bottom' : 0,
				'width' : '100%'
			});
		}
	}
	
	// ajax write to log
	function write2Log(cat, text){
		var url = "/awtl";
		var data = { cat : cat, text : text };
		
		$.ajax({
	        type: 'POST',
	        url: url,
	        data: data,
	        //dataType: 'html',
	        success: function () {
	        	
	        },
	        error: function (data) {
	            //console.log('Error:', data);
	        }
	    });
		
	}
	
	if ( $( ".muuri" ).length ) {
	var grid = new Muuri('.muuri');
	window.addEventListener('load', function () {
	  grid.refreshItems().layout();
	  //console.log(222);
	});
	}
	
	//isEmailCheck 
	function isEmail(email) {
	  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	  return regex.test(email);
	}
});